import { gql } from "@apollo/client";

export const GET_ALL_PRODUCT_CATEGORIES = gql`
  query MyQuery {
    product_categories {
      id
      name
      status
      category_image_url
      disabled

      product_subcategories {
        id
        name
      }
    }
  }
`;
