import React from "react";
import Header from "../components/Header";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_ONE_BLOG } from "../graphql/queries/getOneBlog";

const BlogDetail = () => {
  const { id } = useParams();
  const [loadBlog, getBlogData] = useLazyQuery(GET_ONE_BLOG);
  const blog = getBlogData?.data?.blogs_by_pk
    ? getBlogData.data.blogs_by_pk
    : null;
  React.useEffect(() => {
    loadBlog({
      variables: {
        id: id,
      },
    });
  }, [loadBlog, id]);
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product Detail"} category={"Product"} />
      <div className="bg-white dark:bg-secondary-dark-bg p-6 rounded">
        <div className="flex flex-col gap-10">
          <div className="col-span-12">
            <div className="h-[500px]">
              <img
                className="h-full w-full object-contain"
                src={blog?.blog_image_url}
                alt={"blog_image"}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl">ID</h5>
                <p className="text-gray-400">{blog?.id}</p>
              </div>
            </div>
            <div className="col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl">Name</h5>
                <p className="text-gray-400">{blog?.name}</p>
              </div>
            </div>
          </div>
          <div className="col-span-6">
            <div className="flex flex-col space-y-2">
              <h5 className="text-xl">Description</h5>
              <p
                className="text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: blog?.description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
