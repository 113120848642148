import React from "react";
import { Header } from "../components";
import { ActionButton, IconButton, TextField } from "../anh";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { IMAGE_TYPE } from "../config/common";
import { useMutation } from "@apollo/client";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { uploadImageService } from "../lib/uploadImage";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { ADD_PRODUCT_ACTIVITY } from "../graphql/mutations/addProductActivity";
import * as Yup from "yup";
import clsx from "clsx";

const CreateActivity = () => {
  const { currentColor } = useStateContext();
  const formikRef = React.useRef();
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [formData, setFormData] = React.useState({
    activity_image_url: "",
  });
  const [customError, setCustomError] = React.useState({
    image: "",
  });
  const navigate = useNavigate();
  //Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        activity_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  //Add Brand
  const [add_activity] = useMutation(ADD_PRODUCT_ACTIVITY, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("New Activity  Added ");
      navigate("/activities");
    },
  });

  return (
    <div className="p-6">
      <Header title="Update Brand" category="Brand" />
      <Link
        to={"/activities"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="flex flex-row justify-center">
        <div className="p-8 bg-white dark:bg-secondary-dark-bg rounded shadow w-full lg:w-5/6">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required("activity name required!"),
            })}
            onSubmit={async (values) => {
              try {
                if (!imageFile && !imageFileUrl) {
                  setCustomError({
                    ...currentColor,
                    image: "activity image is required",
                  });
                } else {
                  await uploadImageService(imageFileUrl, imageFile);
                  await add_activity({
                    variables: {
                      activity_image_url: formData.activity_image_url,
                      name: values.name,
                    },
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="mb-4">
                  <label
                    htmlFor="upload"
                    className={clsx(
                      "cursor-pointer border-2 w-full h-[400px] rounded border-dashed relative  flex flex-col justify-center items-center",
                      {
                        "border-red-600": customError.image,
                        "border-gray-400": !customError.image,
                      }
                    )}
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="text-gray-400">
                            Click here to Upload
                          </span>
                        </div>

                        {customError.image && (
                          <h4 className="text-xl text-red-600 mt-6 capitalize">
                            {customError.image}
                          </h4>
                        )}
                      </>
                    )}
                  </label>
                  <p className="text-center text-gray-400">
                    Width 80px and Height 80px
                  </p>
                </div>
                <div className="mb-4">
                  <h4 className="mb-2 dark:text-white text-xl">Name</h4>
                  <TextField
                    name="name"
                    disabled={isSubmitting}
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputSize={"medium"}
                    placeholder={"Name"}
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>
                <ActionButton
                  type="submit"
                  disabled={isSubmitting}
                  text={isSubmitting ? "Creating" : "Save"}
                  bgColor={currentColor}
                  borderRadius={8}
                  width={"24"}
                  color={"#fff"}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateActivity;
