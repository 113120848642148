import React, { useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useMutation } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/20/solid";
import { CREATE_VARIATION_SIZE } from "../graphql/mutations/addVariatonSize";
import { DELETE_VARIATION_SIZE } from "../graphql/mutations/deleteVariationSize";

export default function MutipleSelectBox({
  data,
  selected,
  setSelected,
  name,
  isEdit = false,
  variationId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [delete_variation_size] = useMutation(DELETE_VARIATION_SIZE, {
    onError: (error) => {
      console.log(error.message);
    },
    onCompleted: (result) => {
      // console.log();
    },
  });
  const [add_variation_size] = useMutation(CREATE_VARIATION_SIZE, {
    onError: (error) => {
      console.error(error.message);
    },
    onCompleted: (result) => {
      console.log("Success");
    },
  });
  function isSelected(value) {
    return selected.find((el) => el.name === value.name) ? true : false;
  }

  async function handleSelect(value) {
    if (isEdit && variationId) {
      await add_variation_size({
        variables: {
          fk_variation_id: variationId,
          fk_variation_size_id: value.id,
        },
      });
    }
    if (!isSelected(value)) {
      const selectedUpdated = [
        ...selected,
        data.find((el) => el.name === value.name),
      ];

      setSelected(selectedUpdated);
    } else {
      handleDeselect(value);
    }
    setIsOpen(true);
  }

  async function handleDeselect(value) {
    if (isEdit && variationId) {
      await delete_variation_size({
        variables: {
          fk_variation_id: variationId,
          fk_variation_size_id: value.id,
        },
      });
    }
    const selectedUpdated = selected.filter((el) => el.name !== value.name);

    setSelected(selectedUpdated);
    setIsOpen(true);
  }

  return (
    <div className="flex items-center justify-center">
      <div className="w-full">
        <Listbox
          as="div"
          className="space-y-1 w-full"
          value={selected}
          onChange={(value) => handleSelect(value)}
          open={isOpen}
        >
          {() => (
            <>
              <div className="relative">
                <span className="inline-block w-full  rounded-md shadow-sm">
                  <Listbox.Button
                    className="cursor-default relative w-full rounded-md border border-gray-300 z-40  bg-white dark:bg-secondary-dark-bg dark:border-white pl-3 py-3 pr-10 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setIsOpen(!isOpen)}
                    open={isOpen}
                  >
                    <span className="block ">
                      {selected.length < 1 ? (
                        <p className="text-gray-400 ">{name}</p>
                      ) : (
                        <p className="dark:text-white text-back">
                          {selected.map((item) => item.name).join(", ")}
                        </p>
                      )}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  unmount={false}
                  show={isOpen}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full z-50 rounded-md bg-white dark:bg-main-dark-bg  shadow-lg"
                >
                  <Listbox.Options
                    static
                    className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 "
                  >
                    {data.map((person) => {
                      const selected = isSelected(person);
                      return (
                        <Listbox.Option key={person.id} value={person}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? "text-white dark:bg-blue-500"
                                  : "text-gray-900"
                              } cursor-pointer hover:bg-blue-600 dark:text-white hover:text-white select-none relative py-2 pl-8 pr-4 group`}
                            >
                              <span
                                className={`${
                                  selected ? "font-semibold " : "font-normal"
                                } block truncate`}
                              >
                                {person?.name}
                              </span>
                              {selected && (
                                <span
                                  className={`${
                                    active ? "text-white" : "text-blue-600"
                                  } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                >
                                  <CheckIcon className="w-5 h-5 group-hover:text-white" />
                                </span>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
}
