import { gql } from "@apollo/client";

export const GET_SUBCATEGORY_BY_CATID = gql`
  query MyQuery($fk_category_id: Int!) {
    product_subcategories(where: { fk_category_id: { _eq: $fk_category_id } }) {
      id
      name
    }
  }
`;
