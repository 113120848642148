import { gql } from "@apollo/client";

export const GET_ALL_ORDERS = gql`
  query MyQuery {
    orders {
      id

      shipping_address {
        name
        phone
        address
        code
      }
      payment_method
      status
      totalAmount
      updated_at
      order_items {
        id
        qty
        variation_color {
          name
          color_code
        }
        size {
          name
        }
        totalPrice
        product {
          price
          name
        }
        product_variation {
          price
          product_image_url
          id
        }
      }
      user {
        id
        name
      }
    }
  }
`;
