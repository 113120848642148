import React from "react";
import Header from "../components/Header";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { IMAGE_TYPE } from "../config/common";
import { ActionButton, IconButton, TextField } from "../anh";
import { useNavigate, useParams } from "react-router-dom";
import { uploadImageService } from "../lib/uploadImage";
import { Form, Formik } from "formik";
import { GET_ONE_BLOG } from "../graphql/queries/getOneBlog";
import { UPDATE_BLOG } from "../graphql/mutations/updateBlog";
import { MyRichTextEditor } from "../components";
import RichTextEditor from "react-rte";
const UpdateBlog = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const blogId = parseInt(id);
  const [formData, setFormData] = React.useState({
    blog_image_url: "",
    description: "",
  });
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [description, setDescription] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const formikRef = React.useRef();
  //Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log("result", result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        blog_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  //Get Product Collection Detail
  const [loadBlog, getBlogData] = useLazyQuery(GET_ONE_BLOG);
  const blog = getBlogData?.data?.blogs_by_pk
    ? getBlogData.data.blogs_by_pk
    : null;
  React.useEffect(() => {
    loadBlog({
      variables: {
        id: blogId,
      },
    });
  }, [blogId, loadBlog]);

  //Show Value In InputBox
  React.useEffect(() => {
    if (blog) {
      formikRef.current.setFieldValue("name", blog?.name);
      setImageFile(blog?.blog_image_url ?? "");
      setImagePreview(blog?.blog_image_url ?? "");
      setFormData({
        blog_image_url: blog?.blog_image_url ?? "",
      });
      setDescription(
        RichTextEditor.createValueFromString(blog.description, "html")
      );
    }
  }, [blog]);
  const [update_blog] = useMutation(UPDATE_BLOG, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: (result) => {
      setFormData({});
      toast.success("Updated Blog Successfully!");
      navigate("/blogs");
    },
  });

  return (
    <div className="p-8 dark:text-white">
      <Header title={"Update Product Collection"} category="Collecton" />
      <div className="mt-4 flex flex-row justify-center">
        <div className="p-8 bg-white rounded dark:bg-secondary-dark-bg w-full lg:w-5/6 shadow">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            onSubmit={async (values) => {
              try {
                if (imageFile && imageFileUrl) {
                  await uploadImageService(imageFileUrl, imageFile);
                }
                await update_blog({
                  variables: {
                    id: blog?.id,
                    blog_image_url: formData.blog_image_url,
                    name: values.name,
                    description: formData.description,
                  },
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="mb-4">
                  <label
                    htmlFor="upload"
                    className="cursor-pointer border-2 w-full h-[400px]  rounded border-dashed dark:border-gray-400 flex flex-col justify-center items-center relative"
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="dark:text-gray-400 text-white">
                            Click here to Upload
                          </span>
                        </div>
                      </>
                    )}
                  </label>
                </div>
                <div className="mb-3">
                  <h4 className="mb-2">Title</h4>
                  <TextField
                    name="name"
                    id={"name"}
                    onChange={handleChange}
                    placeholder="Name"
                    type="text"
                    value={values.name}
                    inputSize="medium"
                    fullWidth
                  />
                </div>
                {/* Description */}
                <div className="mb-4">
                  <h4 className="text-gray-600 dark:text-white mb-2">
                    Description
                  </h4>
                  <MyRichTextEditor
                    description={description}
                    setDescription={setDescription}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
                <div className="">
                  <ActionButton
                    disabled={isSubmitting}
                    type="submit"
                    text={isSubmitting ? "Creating.." : "Save"}
                    bgColor={currentColor}
                    width={"full"}
                    borderRadius={8}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UpdateBlog;
