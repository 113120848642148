import React from "react";
import { ActionButton, TextField } from "../../anh";
import { useStateContext } from "../../contexts/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN,
  IS_LOGGED_IN_KEY,
  IS_LOGGED_IN_VALUE,
} from "../../config/app";
import logo from "../../data/logo.png";
import { VENDOR_LOGIN } from "../../graphql/mutations/VendorLogin";

const Login = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [login] = useMutation(VENDOR_LOGIN, {
    onCompleted: ({ VendorLogin }) => {
      if (VendorLogin) {
        setAuthenticated(VendorLogin.accessToken);
        toast.success("Welcome Back!");
        navigate("/");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const setAuthenticated = (accessToken) => {
    if (!accessToken) return;
    Cookies.set(IS_LOGGED_IN_KEY, IS_LOGGED_IN_VALUE);
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  };
  return (
    <div className="flex dark:bg-main-dark-bg bg-light-gray flex-col justify-center items-center h-screen px-4">
      <div className="bg-white sm:max-w-[400px] max-w-full w-full dark:bg-secondary-dark-bg p-6 rounded">
        <div className="flex  flex-row justify-center items-center">
          <Link
            to="/"
            className="dark:bg-transparent inline-block mt-3 bg-black p-3 rounded-full"
          >
            <img src={logo} alt="logo" className="w-10 h-10 object-contain" />
          </Link>
        </div>
        <h1 className="text-2xl font-bold dark:text-white text-black mb-6">
          Login
        </h1>
        <Formik
          initialValues={{ name: "", phone: "", password: "" }}
          onSubmit={async (values, { setErrors }) => {
            try {
              if (!values.phone) {
                setErrors({ phone: "phone is required" });
              } else if (!values.password) {
                setErrors({ password: "password is required" });
              } else {
                await login({
                  variables: {
                    phone: values.phone,
                    password: values.password,
                  },
                });
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-4">
                <h4 className="mb-2 dark:text-gray-400 text-white">Phone</h4>
                <TextField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="phone"
                  id="phone"
                  inputSize={"medium"}
                  fullWidth
                  disabled={isSubmitting}
                  placeholder={"Phone"}
                />
                {errors.phone && (
                  <span className="text-red-500">{errors.phone}</span>
                )}
              </div>
              <div className="mb-4">
                <h4 className="mb-2 dark:text-gray-400 text-white">Password</h4>
                <TextField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  name="password"
                  id="password"
                  inputSize={"medium"}
                  fullWidth
                  placeholder={"Password"}
                  type="password"
                />
                {errors.password && (
                  <span className="text-red-500">{errors.password}</span>
                )}
              </div>
              <div className="mb-4">
                <ActionButton
                  type="submit"
                  disabled={isSubmitting}
                  text={isSubmitting ? "Creating..." : "Login"}
                  width={"full"}
                  borderRadius={8}
                  bgColor={currentColor}
                  color="#fff"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
