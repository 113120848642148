import { gql } from "@apollo/client";

export const GET_VARIATION_SIZE = gql`
  query MyQuery($fk_variation_id: Int!) {
    variation_sizes(where: { fk_variation_id: { _eq: $fk_variation_id } }) {
      size {
        id
        name
      }
    }
  }
`;
