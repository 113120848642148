import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_STATUS = gql`
  mutation MyMutation($id: Int!, $status: Boolean!, $disabled: Boolean!) {
    update_products_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status, disabled: $disabled }
    ) {
      status
      disabled
    }
  }
`;
