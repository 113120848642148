import React from "react";
import Header from "../components/Header";
import { IconButton } from "../anh";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_PRODUCT_MODELS } from "../graphql/queries/getAllProductModels";

const ProductModel = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [loadProductModel, getProductModelData] = useLazyQuery(
    GET_ALL_PRODUCT_MODELS
  );
  const product_models =
    Array.isArray(getProductModelData?.data?.product_types) &&
    getProductModelData?.data?.product_types.length > 0
      ? getProductModelData?.data?.product_types
      : [];
  React.useEffect(() => {
    loadProductModel({
      variables: {},
      fetchPolicy: "network-only",
    });
  }, [loadProductModel]);

  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product Model"} category="Model" />
      <Link
        to={"/product-models/add"}
        style={{ background: currentColor }}
        className="text-white outline-none px-3 py-2 rounded-lg"
      >
        Add Product Model
      </Link>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Product Model
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Sub Category
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {product_models.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4">{item.id}</td>

                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.product_subcategory.name}</td>

                  <td className="px-6 py-4">
                    <div className="flex flex-row">
                      <IconButton
                        onClick={() =>
                          navigate(`/product-models/edit/${item.id}`)
                        }
                        icon={
                          <PencilSquareIcon
                            style={{ color: currentColor }}
                            className="w-6 h-6"
                          />
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductModel;
