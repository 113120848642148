import React from "react";
import RichTextEditor from "react-rte";
import { toolbarConfig } from "../lib/rte-toolbar";
import clsx from "clsx";

const MyRichTextEditor = ({
  description,
  setDescription,
  setFormData,
  formData,
  errorMsg,
}) => {
  const handleDescripton = (value) => {
    setDescription(value);
    setFormData({
      ...formData,
      description: value.toString("html"),
    });
  };
  return (
    <div>
      <RichTextEditor
        onChange={handleDescripton}
        value={description}
        className={clsx(
          "bg-white dark:text-white text-black dark:bg-secondary-dark-bg",
          {
            "border !border-red-600": errorMsg,
            "border-none": !errorMsg,
          }
        )}
        toolbarConfig={toolbarConfig}
      />
      {errorMsg && <span className="text-red-600 capitalize">{errorMsg}</span>}
    </div>
  );
};

export default MyRichTextEditor;
