import { gql } from "@apollo/client";

export const ADD_COLOR = gql`
  mutation MyMutation($name: String, $color_code: String!) {
    insert_variation_colors_one(
      object: { name: $name, color_code: $color_code }
    ) {
      id
    }
  }
`;
