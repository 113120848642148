import React from "react";
import clsx from "clsx";

const TextField = ({
  type,
  disabled,
  inputSize,
  error,
  placeholder,
  className,
  fullWidth,
  helperText,
  value,
  onChange,
  id,
  onBlur,
}) => {
  return (
    <div>
      <div className="relative">
        <input
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          className={clsx(
            "block w-full border bg-inherit border-gray-200 rounded-md placeholder-slate-400 dark:placeholder-slate-300  dark:text-white disabled:text-gray-500 dark:disabled:text-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:shadow-none disabled:hover:ring-0 hover:ring-1 dark:hover:ring-white hover:ring-black invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500",
            {
              "px-4 py-[8.5px]": inputSize === "small",
              "px-4 py-[12.5px]": inputSize === "medium",
            },
            fullWidth ? "max-w-full" : "max-w-sm",
            error && "!border-red-600 placeholder-red-600",
            className
          )}
        />
      </div>
      {helperText && (
        <span
          className={clsx(
            "text-sm  mt-[3px] px-[14px]",
            error && "text-red-600 capitalize"
          )}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default TextField;
