import { gql } from "@apollo/client";

export const GET_ALL_PRODUCT_MODELS = gql`
  query MyQuery {
    product_types {
      id
      name
      product_subcategory {
        id
        name

        product_category {
          id
          name
        }
      }
    }
  }
`;
