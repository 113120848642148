import { gql } from "@apollo/client";

export const UPDATE_COLOR_BY_ID = gql`
  mutation MyMutation($id: Int!, $name: String, $color_code: String!) {
    update_variation_colors_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, color_code: $color_code }
    ) {
      id
    }
  }
`;
