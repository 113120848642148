import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_CATEGORY = gql`
  mutation MyMutation($id: Int!, $name: String!, $category_image_url: String!) {
    update_product_categories_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, category_image_url: $category_image_url }
    ) {
      id
      name
    }
  }
`;
