import { gql } from "@apollo/client";

export const GET_PRODUCT_TYPES_BY_ID = gql`
  query MyQuery($fk_subcategory_id: Int!) {
    product_types(where: { fk_subcategory_id: { _eq: $fk_subcategory_id } }) {
      id
      name
    }
  }
`;
