import { gql } from "@apollo/client";

export const UPDATE_PRODUCT = gql`
  mutation MyMutation(
    $id: Int!
    $name: String!
    $sub_title: String!
    $promotion: Boolean!
    $disabled: Boolean!
    $fk_vendor_id: Int!
    $fk_product_activity_id: Int!
    $fk_product_brand_id: Int!
    $fk_product_category_id: Int!
    $fk_product_type_id: Int!
    $fk_product_collection_id: Int!
    $fk_body_type_id: Int!
    $description: String!
    $product_image_url: String!
  ) {
    update_products_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        sub_title: $sub_title
        promotion: $promotion
        fk_product_brand_id: $fk_product_brand_id
        fk_vendor_id: $fk_vendor_id
        fk_product_activity_id: $fk_product_activity_id
        fk_product_category_id: $fk_product_category_id
        fk_product_type_id: $fk_product_type_id
        fk_product_collection_id: $fk_product_collection_id
        product_image_url: $product_image_url
        fk_body_type_id: $fk_body_type_id
        disabled: $disabled
        description: $description
      }
    ) {
      id
    }
  }
`;
