import React from "react";
import Header from "../components/Header";
import { useLazyQuery } from "@apollo/client";
import clsx from "clsx";
import { GET_ALL_ORDER_ITEMS } from "../graphql/queries/getAllOrderItems";

const Orders = () => {
  const [loadOrder, getOrderData] = useLazyQuery(GET_ALL_ORDER_ITEMS);
  const [data, setData] = React.useState([]);
  const [category, setCategory] = React.useState("All");

  React.useEffect(() => {
    loadOrder({
      variables: {},
    });
  }, [loadOrder]);
  React.useEffect(() => {
    if (getOrderData?.data?.order_items) {
      setData(getOrderData?.data?.order_items);
    }
  }, [getOrderData?.data?.order_items]);

  const handleFilter = (value) => {
    setCategory(value);
  };

  React.useEffect(() => {
    if (category === "All") {
      setData(getOrderData?.data?.order_items);
    } else {
      const updateData = getOrderData?.data?.order_items.filter(
        ({ order }) => order.status === category.toUpperCase()
      );
      setData(updateData);
    }
  }, [category, getOrderData?.data?.order_items]);
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Customer Orders"} category="Orders" />
      <div className="flex flex-row border-b border-gray-400 items-center">
        {["All", "Pending", "Delivery", "Complete"].map((value, index) => (
          <div
            key={index}
            onClick={() => handleFilter(value)}
            className={clsx("px-3 py-2  mx-2 cursor-pointer", {
              "border-red-500 border-b-2": value === category,
            })}
          >
            {value}
          </div>
        ))}
      </div>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Item
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Username
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Subtotal
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {Array.isArray(data) &&
                data.length > 0 &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4">{item.id}</td>
                    <td className="px-6 py-4 flex flex-row">
                      {item.product_variation.product_image_url && (
                        <img
                          src={item.product_variation.product_image_url}
                          alt={item.product_variation.name}
                          className="w-10 h-10 rounded-full object-cover"
                        />
                      )}
                    </td>
                    <td className="px-6 py-4">{item.product.name}</td>
                    <td className="px-6 py-4">{item.qty}</td>
                    <td className="px-6 py-4">
                      {item.product_variation.price}
                    </td>
                    <td className="px-6 py-4">{item.totalPrice}</td>
                    <td className="px-6 py-4">
                      {new Date(item.order.updated_at).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <span
                        className={clsx("text-white rounded px-1", {
                          "bg-red-500": item.order.status === "PENDING",
                          "bg-blue-500": item.order.status === "DELIVERY",
                          "bg-green-500": item.order.status === "COMPLETE",
                        })}
                      >
                        {item.order.status}
                      </span>
                    </td>
                    <td className="px-6 py-4">#</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Orders;
