import React from "react";
import Header from "../components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SINGLE_PRODUCT } from "../graphql/queries/getOneProduct";
import clsx from "clsx";
import { useStateContext } from "../contexts/ContextProvider";
import { TrashIcon } from "@heroicons/react/20/solid";
import { ActionButton, IconButton } from "../anh";
import { DELETE_PRODUCT_IMAGE } from "../graphql/mutations/deleteProductImage";
import { toast } from "react-toastify";
import { UPDATE_PRODUCT_STATUS } from "../graphql/mutations/updateProductStatus";
import ProductionVariationTable from "./ProductionVariationTable";
const ProductDetail = () => {
  const { id } = useParams();
  const productId = parseInt(id);
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [status, setStatus] = React.useState();
  const [disabled, setDisabled] = React.useState();
  //Get One Product
  const [loadProductData, getProductData] = useLazyQuery(GET_SINGLE_PRODUCT);
  const product = getProductData?.data?.products_by_pk
    ? getProductData?.data?.products_by_pk
    : null;
  React.useEffect(() => {
    loadProductData({
      variables: {
        id: productId,
      },
      fetchPolicy: "network-only",
    });
  }, [loadProductData, productId]);
  //Handle Status and Disabled
  React.useEffect(() => {
    if (product) {
      setStatus(product?.status);
      setDisabled(product?.disabled);
    }
  }, [product]);
  //Handle Delete Product Image
  const [deleteProductImage] = useMutation(DELETE_PRODUCT_IMAGE, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Product Image Deleted!");
    },
  });
  const handleProductImageDel = async (deleteId) => {
    await deleteProductImage({
      variables: {
        id: deleteId,
      },
    });
  };

  //Update Status and Disabled
  const [update_status] = useMutation(UPDATE_PRODUCT_STATUS, {
    onCompleted: (result) => {
      setStatus(result?.update_products_by_pk?.status);
      setDisabled(result?.update_products_by_pk?.disabled);
    },
    refetchQueries: () => [
      {
        query: GET_SINGLE_PRODUCT,
      },
    ],
  });
  const handleStatus = async (statusId) => {
    await update_status({
      variables: {
        id: statusId,
        status: !status,
        disabled,
      },
    });
  };
  const handleDisabled = async (disabledId) => {
    await update_status({
      variables: {
        id: disabledId,
        status,
        disabled: !disabled,
      },
    });
  };

  return (
    <div className="p-8">
      <Header title={"Product Detail"} category={"Product"} />
      <Link
        to={"/products"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="bg-white dark:bg-secondary-dark-bg p-6 rounded">
        <div className="flex flex-col gap-10">
          <div className="col-span-12">
            <div className="h-[500px]">
              <img
                className="h-full w-full object-contain"
                src={product?.product_image_url}
                alt={"product_image"}
              />
            </div>
          </div>
          {/* Detail Data */}
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">ID</h5>
                <p className="text-gray-400">{product?.id}</p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">Name</h5>
                <p className="text-gray-400">{product?.name}</p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">Category</h5>
                <p className="text-gray-400">
                  {product?.product_category?.name}
                </p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">Brand</h5>
                <p className="text-gray-400">{product?.brand?.name}</p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">
                  Product Activity
                </h5>
                <p className="text-gray-400">{product?.activity?.name}</p>
              </div>
            </div>

            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">
                  Product Collection
                </h5>
                <p className="text-gray-400">
                  {product?.product_collection?.name}
                </p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-back dark:text-white">Vendor</h5>
                <p className="text-gray-400">
                  {product?.vendor?.name} , {product?.vendor?.phone}
                </p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl dark:text-white text-back">
                  Product Model
                </h5>
                <p className="text-gray-400">{product?.product_type.name}</p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl dark:text-white text-back">
                  Sub Category
                </h5>
                <p className="text-gray-400">
                  {product?.product_subcategory.name}
                </p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl dark:text-white text-black">
                  Body Type
                </h5>
                <p className="text-gray-400">{product?.body_type?.name}</p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl dark:text-white text-black">Price</h5>
                <p className="text-gray-400">{product?.price}</p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl dark:text-white text-black">
                  Promotion
                </h5>
                <p className="text-gray-400">
                  {product?.promotion ? "Yes" : "No"}
                </p>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="">
                <h5 className="text-xl text-black dark:text-white mb-3">
                  Status
                </h5>
                <span
                  className={clsx("text-white inline px-2 rounded", {
                    "bg-green-400": status,
                    "bg-red-400": !status,
                  })}
                >
                  {" "}
                  {status ? "Active" : "Pending"}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Description */}
        <div className="mt-10">
          <h5 className="text-xl mb-2 dark:text-white text-black">
            Description
          </h5>
          <p
            className="text-gray-400"
            dangerouslySetInnerHTML={{
              __html: product?.description,
            }}
          />

          <div className="flex flex-col mt-4">
            <h4 className="mb-3 text-black dark:text-white">Actions</h4>
            <div className="flex flex-row items-center">
              <div className="flex flex-row space-x-3">
                <ActionButton
                  onClick={() => handleStatus(product.id)}
                  text="Status"
                  bgColor={status ? currentColor : "red"}
                  borderRadius={10}
                  color={"#fff"}
                />
                <ActionButton
                  onClick={() => handleDisabled(product.id)}
                  text="Disabled"
                  bgColor={disabled ? "red" : currentColor}
                  borderRadius={10}
                  color={"#fff"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Product Images */}
        <div className="mt-10">
          {Array.isArray(product?.product_variations) &&
            product?.product_variations.length > 0 && (
              <>
                <h1 className="text-xl mb-4 dark:text-white text-black">
                  Product Images
                </h1>
                <div className="my-5">
                  <ActionButton
                    onClick={() =>
                      navigate(`/products/${productId}/add-images`)
                    }
                    bgColor={currentColor}
                    color={"#fff"}
                    text="New Image"
                    borderRadius={10}
                  />
                </div>
              </>
            )}

          {Array.isArray(product?.product_images) &&
            product?.product_images.length > 0 && (
              <div className="h-[800px] overflow-y-auto">
                <div className="grid grid-cols-12 gap-4">
                  {Array.isArray(product?.product_images) &&
                    product?.product_images.length > 0 &&
                    product?.product_images.map((item) => (
                      <div
                        key={item.id}
                        className="md:col-span4 col-span-6 lg:col-span-3 relative"
                      >
                        <img src={item.product_image_url} alt={item.name} />
                        <IconButton
                          onClick={() => handleProductImageDel(item.id)}
                          className={"absolute right-0 top-0"}
                          icon={<TrashIcon className="w-5 h-5" />}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
        </div>
      </div>
      {/* Variation Table */}
      <div className="mt-10 p-6 bg-white dark:bg-secondary-dark-bg rounded">
        <ProductionVariationTable data={product?.product_variations} />
        <button
          onClick={() => navigate(`/products/${product?.id}/add-variation`)}
          style={{ background: currentColor }}
          className="text-white outline-none mt-10 px-3 py-2 rounded-lg"
        >
          New Variation
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;
