import React from "react";
import { Header } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { Form, Formik } from "formik";
import { ActionButton, TextField } from "../anh";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { ADD_SIZE } from "../graphql/mutations/addSize";
import { toast } from "react-toastify";
const AddVariationSize = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [add_size] = useMutation(ADD_SIZE, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Added Size Sucess!");
      navigate("/variation-sizes");
    },
  });
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Create Sizes"} category="Sizes" />
      <Link
        to={"/variation-sizes"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="mt-4 flex flex-row justify-center">
        <div className="p-8 bg-white rounded dark:bg-secondary-dark-bg w-full lg:w-5/6 shadow">
          <Formik
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required("name required!"),
            })}
            onSubmit={async (values) => {
              try {
                await add_size({
                  variables: {
                    name: values.name,
                  },
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              isValid,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="mb-3">
                  <h4 className="mb-2 dark:text-white text-back">Name</h4>
                  <TextField
                    name="name"
                    id={"name"}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Name"
                    type="text"
                    inputSize="medium"
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>

                <div className="">
                  <ActionButton
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    text={isSubmitting ? "Creating.." : "Save"}
                    bgColor={currentColor}
                    width={"full"}
                    color={"#fff"}
                    borderRadius={8}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddVariationSize;
