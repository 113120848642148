import { gql } from "@apollo/client";

export const ADD_PRODUCT_MODEL = gql`
  mutation MyMutation($fk_subcategory_id: Int!, $name: String!) {
    insert_product_types_one(
      object: { fk_subcategory_id: $fk_subcategory_id, name: $name }
    ) {
      id
    }
  }
`;
