import { gql } from "@apollo/client";

export const IMAGE_UPLOAD = gql`
  mutation MyMutation($contentType: String!) {
    getImageUploadUrl(contentType: $contentType) {
      contentType
      error
      imageUploadUrl
      imageName
      message
    }
  }
`;
