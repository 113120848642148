import React from "react";
import Header from "../components/Header";
import { IconButton } from "../anh";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import clsx from "clsx";
// import { toast } from "react-toastify";
import { GET_ALL_SUBCATEGORIES } from "../graphql/queries/getAllSubCategory";
// import { DELETE_SUBCATEGORY } from "../graphql/mutations/deleteSubCategory";

const SubCategories = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [loadSubCategory, getSubCategoryData] = useLazyQuery(
    GET_ALL_SUBCATEGORIES
  );
  const subcategories =
    Array.isArray(getSubCategoryData?.data?.product_subcategories) &&
    getSubCategoryData?.data?.product_subcategories.length > 0
      ? getSubCategoryData?.data?.product_subcategories
      : [];
  console.log(subcategories);
  React.useEffect(() => {
    loadSubCategory({
      variables: {},
      fetchPolicy: "network-only",
    });
  }, [loadSubCategory]);

  //Delete Product Brand
  // const [delete_product_category] = useMutation(DELETE_SUBCATEGORY, {
  //   onError: (error) => {
  //     toast.error(error.message);
  //   },
  //   onCompleted: (result) => {
  //     toast.success("Delete Category Successfully");
  //     navigate("/subcategories");
  //   },
  // });
  // const handleDeleteCategory = async (deleteId) => {
  //   await delete_product_category({
  //     variables: {
  //       id: deleteId,
  //     },
  //   });
  // };
  return (
    <div className="p-8 dark:text-white">
      <Header title={"SubCategories"} category="SubCategories" />
      <Link
        to={"/subcategories/add"}
        style={{ background: currentColor }}
        className="text-white outline-none px-3 py-2 rounded-lg"
      >
        Add SubCategory
      </Link>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  SubCategory
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {subcategories.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4">{item.id}</td>

                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.product_category.name}</td>

                  <td className="px-6 py-4">
                    <span
                      className={clsx("text-white px-2 rounded", {
                        "bg-green-400": item.status,
                        "bg-red-400": !item.status,
                      })}
                    >
                      {" "}
                      {item.status ? "Active" : "Pending"}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-row">
                      {/* <IconButton
                        onClick={() => handleDeleteCategory(item.id)}
                        icon={<TrashIcon className="w-6 h-6" />}
                      /> */}
                      <IconButton
                        onClick={() =>
                          navigate(`/subcategories/edit/${item.id}`)
                        }
                        icon={
                          <PencilSquareIcon
                            style={{ color: currentColor }}
                            className="w-6 h-6"
                          />
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubCategories;
