import { gql } from "@apollo/client";

export const GET_ALL_SUBCATEGORIES = gql`
  query MyQuery {
    product_subcategories {
      id
      name
      status
      product_category {
        id
        name
      }
    }
  }
`;
