import { gql } from "@apollo/client";

export const UPDATE_BLOG = gql`
  mutation MyMutation(
    $id: Int!
    $name: String!
    $description: String!
    $blog_image_url: String!
  ) {
    update_blogs_by_pk(
      pk_columns: { id: $id }
      _set: {
        blog_image_url: $blog_image_url
        name: $name
        description: $description
      }
    ) {
      blog_image_url
      description
      id
      name
      status
    }
  }
`;
