import clsx from "clsx";
import React from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { IconButton } from "../anh";
import { useUI } from "../contexts/UIContext";
import { Dialog } from "../components";
import UpdateProductVariation from "./UpdateProductVariation";
import { Link, useNavigate } from "react-router-dom";
const ProductionVariationTable = ({ data }) => {
  const { currentColor } = useStateContext();
  const { dialog } = useUI();
  const navigate = useNavigate();

  //Handle Delete Variation

  // const handleDelete = async (deleteId) => {
  //   await delete_variation({
  //     variables: {
  //       id: deleteId,
  //     },
  //   });
  //   toast.success("Variation Delete Successfully!");
  // };

  return (
    <div className="overflow-x-auto rounded-lg border border-gray-200  dark:border-none">
      <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
        <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
          <tr>
            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
              Image
            </th>
            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
              Price
            </th>

            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map((item) => (
              <tr key={item.id} className="">
                <td className="px-6 py-4">{item.id}</td>
                <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                  <div className="relative h-14 w-14">
                    <img
                      src={item.product_image_url}
                      alt={item.name}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                </th>
                <td className="px-6 py-4">{item.name}</td>
                <td className="px-6 py-4">{item.price}</td>

                <td className="px-6 py-4">
                  <span
                    className={clsx("text-white px-2 rounded", {
                      "bg-green-400": item.status,
                      "bg-red-400": !item.status,
                    })}
                  >
                    {" "}
                    {item.status ? "Active" : "Pending"}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-row items-center">
                    <Link
                      to={`/products/variation/${item.id}`}
                      className="px-3 py-2 rounded text-white bg-blue-500"
                    >
                      Detail
                    </Link>
                    {/* <IconButton
                      onClick={() => handleDelete(item.id)}
                      icon={<TrashIcon className="w-6 text-red-400 h-6" />}
                    /> */}
                    <IconButton
                      onClick={() =>
                        navigate(`/products/edit-variation/${item.id}`)
                      }
                      icon={
                        <PencilSquareIcon
                          style={{ color: currentColor }}
                          className="w-6  h-6"
                        />
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {dialog.name === "UPDATE_PRODUCT_VARIATION" && (
        <UpdateProductVariationUI />
      )}
    </div>
  );
};

export default ProductionVariationTable;

const UpdateProductVariationUI = () => {
  const { dialog, setDialog } = useUI();
  return dialog.name === "UPDATE_PRODUCT_VARIATION" ? (
    <Dialog
      fullWidth
      maxWidth="xl"
      open
      onClose={() =>
        setDialog({
          name: "",
          data: null,
        })
      }
    >
      <UpdateProductVariation />
    </Dialog>
  ) : null;
};
