import React from "react";
import Header from "../components/Header";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { IconButton, TextField } from "../anh";
import { useStateContext } from "../contexts/ContextProvider";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SINGLE_PRODUCT_ID } from "../graphql/queries/getOneProductById";
import { UPDATE_PRODUCT } from "../graphql/mutations/updateProduct";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { IMAGE_TYPE } from "../config/common";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SelectBox } from "../anh";
import { uploadImageService } from "../lib/uploadImage";
import RichTextEditor from "react-rte";
import MyRichTextEditor from "../components/MyRichTextEditor";
import * as Yup from "yup";
import clsx from "clsx";
const UpdateProduct = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const productId = parseInt(id);
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [description, setDescription] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const [formData, setFormData] = React.useState({
    product_image_url: "",
    description: "",
  });
  const [customError, setCustomError] = React.useState({
    category: "",
    subcategory: "",
    description: "",
    gender: "",
    activity: "",
    collection: "",
    brand: "",
    vendor: "",
    image: "",
  });
  const [categorySelect, setCategorySelect] = React.useState();
  const [subCategorySelect, setSubCategorySelect] = React.useState();
  const [brandSelect, setBrandSelect] = React.useState();
  const [activitySelect, setActivitySelect] = React.useState();
  const [collectionSelect, setCollectionSelect] = React.useState();
  const [vendorSelect, setVendorSelect] = React.useState();
  const [bodyTypeSelect, setBodyTypeSelect] = React.useState();
  const [productModelSelect, setProductModelSelect] = React.useState();
  const formikRef = React.useRef();

  //Get All Product By Id
  const [loadProductData, getProductData] = useLazyQuery(GET_SINGLE_PRODUCT_ID);
  const product = getProductData?.data?.products_by_pk
    ? getProductData?.data.products_by_pk
    : null;
  const categories =
    Array.isArray(getProductData?.data?.product_categories) &&
    getProductData?.data?.product_categories.length > 0
      ? getProductData?.data.product_categories
      : [];
  const subcategories =
    Array.isArray(getProductData?.data?.product_subcategories) &&
    getProductData?.data?.product_subcategories.length > 0
      ? getProductData?.data.product_subcategories
      : [];

  const brands =
    Array.isArray(getProductData?.data?.product_brands) &&
    getProductData?.data?.product_brands.length > 0
      ? getProductData?.data.product_brands
      : [];
  const activities =
    Array.isArray(getProductData?.data?.product_activities) &&
    getProductData?.data?.product_activities.length > 0
      ? getProductData?.data.product_activities
      : [];
  const collections =
    Array.isArray(getProductData?.data?.product_collections) &&
    getProductData?.data?.product_collections.length > 0
      ? getProductData?.data.product_collections
      : [];
  const product_models =
    Array.isArray(getProductData?.data?.product_types) &&
    getProductData?.data?.product_types.length > 0
      ? getProductData?.data.product_types
      : [];
  const vendors =
    Array.isArray(getProductData?.data?.vendors) &&
    getProductData?.data?.vendors.length > 0
      ? getProductData?.data.vendors
      : [];
  const body_types =
    Array.isArray(getProductData?.data?.body_types) &&
    getProductData?.data?.body_types.length > 0
      ? getProductData?.data.body_types
      : [];

  React.useEffect(() => {
    if (id) {
      loadProductData({
        variables: {
          id,
        },
      });
    }
  }, [loadProductData, id]);

  //Show Value In Input Box
  React.useEffect(() => {
    setImageFile(product?.product_image_url || "");
    setImagePreview(product?.product_image_url || "");
    formikRef.current.setFieldValue("product_name", product?.name || "");
    formikRef.current.setFieldValue("sub_title", product?.sub_title || "");
    formikRef.current.setFieldValue("price", product?.price || "");
    setFormData({
      description: product?.description,
      product_image_url: product?.product_image_url ?? "",
    });
    setDescription(
      RichTextEditor.createValueFromString(product?.description, "html")
    );
  }, [formikRef, product]);

  //Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log("result", result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        product_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };
  //Update Product
  const [update_product] = useMutation(UPDATE_PRODUCT, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Updated Product Successfully!");
      navigate("/products");
    },
  });
  return (
    <div className="p-6 rounded">
      <Header title="Update Product" category="Product" />
      <Link
        to={"/products"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className=" p-8 bg-white dark:bg-secondary-dark-bg rouned shadow">
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            product_name: "",
            sub_title: "",
            price: "",
          }}
          validationSchema={Yup.object().shape({
            product_name: Yup.string()
              .max(255)
              .required("product name required!"),
            sub_title: Yup.string().max(255).required("product type required!"),
            price: Yup.string().max(255).required("price required!"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (imageFile && imageFileUrl) {
                await uploadImageService(imageFileUrl, imageFile);
              }
              await update_product({
                variables: {
                  id: productId,
                  name: values.product_name,
                  description: formData.description,
                  price: values.price,
                  product_image_url: formData.product_image_url,
                  sub_title: values.sub_title,
                  fk_body_type_id: bodyTypeSelect?.id
                    ? bodyTypeSelect?.id
                    : product?.fk_body_type_id,
                  fk_product_collection_id: collectionSelect?.id
                    ? collectionSelect?.id
                    : product?.fk_product_collection_id,
                  fk_vendor_id: vendorSelect?.id
                    ? vendorSelect?.id
                    : product?.fk_vendor_id,
                  fk_product_brand_id: brandSelect?.id
                    ? brandSelect?.id
                    : product?.fk_product_brand_id,
                  fk_product_activity_id: activitySelect?.id
                    ? activitySelect?.id
                    : product?.fk_product_activity_id,
                  fk_product_category_id: categorySelect?.id
                    ? categorySelect?.id
                    : product?.fk_product_category_id,
                  fk_product_type_id: productModelSelect?.id
                    ? productModelSelect?.id
                    : product?.fk_product_type_id,
                  fk_product_subcategory_id: subCategorySelect?.id
                    ? subCategorySelect?.id
                    : product?.fk_product_subcategory_id,
                  promotion: false,
                  disabled: false,
                },
              });
            } catch (error) {
              console.log(error);
              toast.error(error.message);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="grid grid-cols-12 gap-4 lg:gap-8">
                {/* Product Image */}
                <div className="col-span-12">
                  <label
                    htmlFor="upload"
                    className={clsx(
                      "cursor-pointer border-2 w-full h-[400px] rounded border-dashed relative flex flex-col justify-center items-center",
                      {
                        "border-red-600": customError.image,
                        "border-gray-400": !customError.image,
                      }
                    )}
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="text-gray-400">
                            Click here to Upload
                          </span>
                        </div>

                        {customError.image && (
                          <h4 className="text-xl text-red-600 mt-6 capitalize">
                            {customError.image}
                          </h4>
                        )}
                      </>
                    )}
                  </label>
                </div>
                {/* Product Name */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">Name</h4>
                    <TextField
                      id="product_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="product_name"
                      type={"text"}
                      value={values.product_name}
                      inputSize={"medium"}
                      fullWidth
                      placeholder={"Name"}
                      error={
                        touched.product_name &&
                        errors.product_name &&
                        Boolean(errors.product_name)
                      }
                      helperText={
                        touched.product_name &&
                        errors.product_name &&
                        errors.product_name
                      }
                    />
                  </div>
                </div>
                {/* Sub Title */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Sub Title
                    </h4>
                    <TextField
                      type={"text"}
                      inputSize={"medium"}
                      fullWidth
                      id="sub_title"
                      name="sub_title"
                      value={values.sub_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Sub Title"}
                      error={
                        touched.sub_title &&
                        errors.sub_title &&
                        Boolean(errors.sub_title)
                      }
                      helperText={
                        touched.sub_title &&
                        errors.sub_title &&
                        errors.sub_title
                      }
                    />
                  </div>
                </div>

                {/* Product Price */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Price
                    </h4>
                    <TextField
                      id="price"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="price"
                      type={"text"}
                      value={values.price}
                      inputSize={"medium"}
                      fullWidth
                      placeholder={"Product Price"}
                      error={
                        touched.price && errors.price && Boolean(errors.price)
                      }
                      helperText={touched.price && errors.price && errors.price}
                    />
                  </div>
                </div>
                {/* Categories */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Category
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Category"}
                        selected={
                          !categorySelect
                            ? categories.find(
                                (item) =>
                                  item.id === product?.fk_product_category_id
                              )
                            : categorySelect
                        }
                        setSelected={setCategorySelect}
                        data={categories}
                        errorMsg={customError.category}
                      />
                    </div>
                  </div>
                </div>
                {/*Sub Categories */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Sub Category
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Sub Category"}
                        selected={
                          !subCategorySelect
                            ? subcategories.find(
                                (item) =>
                                  item.id === product?.fk_product_subcategory_id
                              )
                            : subCategorySelect
                        }
                        setSelected={setSubCategorySelect}
                        data={subcategories}
                        errorMsg={customError.subcategory}
                      />
                    </div>
                  </div>
                </div>
                {/*Product Type */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Product Model
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Product Model"}
                        selected={
                          !productModelSelect
                            ? product_models.find(
                                (item) =>
                                  item.id === product?.fk_product_type_id
                              )
                            : productModelSelect
                        }
                        setSelected={setProductModelSelect}
                        data={product_models}
                        errorMsg={customError.subcategory}
                      />
                    </div>
                  </div>
                </div>
                {/* Gender */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Gender
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Gender"}
                        selected={
                          !bodyTypeSelect
                            ? body_types.find(
                                (item) => item.id === product?.fk_body_type_id
                              )
                            : bodyTypeSelect
                        }
                        setSelected={setBodyTypeSelect}
                        data={body_types}
                        errorMsg={customError.gender}
                      />
                    </div>
                  </div>
                </div>
                {/* Activities */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Activities
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Activities"}
                        selected={
                          !activitySelect
                            ? activities.find(
                                (item) =>
                                  item.id === product?.fk_product_activity_id
                              )
                            : activitySelect
                        }
                        setSelected={setActivitySelect}
                        data={activities}
                        errorMsg={customError.activity}
                      />
                    </div>
                  </div>
                </div>
                {/* Collections */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Collections
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Collections"}
                        selected={
                          !collectionSelect
                            ? collections.find(
                                (item) =>
                                  item.id === product?.fk_product_collection_id
                              )
                            : collectionSelect
                        }
                        setSelected={setCollectionSelect}
                        data={collections}
                        errorMsg={customError.collection}
                      />
                    </div>
                  </div>
                </div>
                {/* Brands */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Brands
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Brands"}
                        selected={
                          !brandSelect
                            ? brands.find(
                                (item) =>
                                  item.id === product?.fk_product_brand_id
                              )
                            : brandSelect
                        }
                        setSelected={setBrandSelect}
                        data={brands}
                        errorMsg={customError.brand}
                      />
                    </div>
                  </div>
                </div>
                {/* Vendors */}
                <div className="col-span-12 lg:col-span-6">
                  <div className="">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Vendors
                    </h4>
                    <div className=" bg-main-bg rounded">
                      <SelectBox
                        name={"Vendors"}
                        selected={
                          !vendorSelect
                            ? vendors.find(
                                (item) => item.id === product?.fk_vendor_id
                              )
                            : vendorSelect
                        }
                        setSelected={setVendorSelect}
                        data={vendors}
                        errorMsg={customError.vendor}
                      />
                    </div>
                  </div>
                </div>

                {/* Description */}
                <div className="col-span-12">
                  <h4 className="text-gray-600 dark:text-white mb-2">
                    Description
                  </h4>
                  <MyRichTextEditor
                    description={description}
                    setDescription={setDescription}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
              </div>
              <div className="mt-5 flex flx-row justify-end">
                <button
                  disabled={isSubmitting}
                  style={{ background: currentColor }}
                  className="text-white py-2 px-4 rounded"
                >
                  {isSubmitting ? "Creating" : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateProduct;
