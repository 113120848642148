import { gql } from "@apollo/client";

export const GET_ONE_PRODUCT_BRAND = gql`
  query MyQuery($id: Int!) {
    product_brands_by_pk(id: $id) {
      brand_image_url
      id
      name
    }
  }
`;
