import { gql } from "@apollo/client";

export const GET_ONE_PRODUCT_MODEL = gql`
  query MyQuery($id: Int!) {
    product_types_by_pk(id: $id) {
      id
      name
      product_subcategory {
        id
        name
        product_category {
          id
          name
        }
      }
    }
  }
`;
