import { gql } from "@apollo/client";

export const GET_ALL_COLORS = gql`
  query MyQuery {
    variation_colors {
      id
      name
      color_code
      created_at
      updated_at
    }
  }
`;
