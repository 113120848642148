import { gql } from "@apollo/client";

export const GET_ONE_SIZE = gql`
  query MyQuery($id: Int!) {
    sizes_by_pk(id: $id) {
      name
      id
    }
  }
`;
