import { gql } from "@apollo/client";

export const GET_ALL_PRODUCT_BRANDS = gql`
  query MyQuery {
    product_brands {
      brand_image_url
      id
      name
      status
    }
  }
`;
