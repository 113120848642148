import { gql } from "@apollo/client";

export const GET_PRODUCT_VARIATION_BY_ID = gql`
  query MyQuery($id: Int!) {
    product_variations_by_pk(id: $id) {
      name
      id
      price
      description
      status
      store
      product_image_url
      variation_color {
        id
        color_code

        name
      }
    }

    sizes {
      id
      name
    }
  }
`;
