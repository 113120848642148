import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_MODEL = gql`
  mutation MyMutation($id: Int!, $name: String!, $fk_subcategory_id: Int!) {
    update_product_types_by_pk(
      pk_columns: { id: $id }
      _set: { fk_subcategory_id: $fk_subcategory_id, name: $name }
    ) {
      fk_subcategory_id
      id
    }
  }
`;
