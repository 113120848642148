import React from "react";
import Header from "../components/Header";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_ONE_PRODUCT_COLLECTION } from "../graphql/queries/getOneProductCollection";

const ProductCollectionDetail = () => {
  const { id } = useParams();
  const [loadProductCollection, getProductCollection] = useLazyQuery(
    GET_ONE_PRODUCT_COLLECTION
  );
  const collection = getProductCollection?.data?.product_collections_by_pk
    ? getProductCollection.data.product_collections_by_pk
    : null;
  React.useEffect(() => {
    loadProductCollection({
      variables: {
        id: id,
      },
    });
  }, [loadProductCollection, id]);
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product Detail"} category={"Product"} />
      <div className="bg-white dark:bg-secondary-dark-bg p-6 rounded">
        <div className="flex flex-col gap-10">
          <div className="col-span-12">
            <div className="h-[500px]">
              <img
                className="h-full w-full object-contain"
                src={""}
                alt={"product_image"}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl">ID</h5>
                <p className="text-gray-400">{collection?.id}</p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl">Name</h5>
                <p className="text-gray-400">{collection?.name}</p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl">Vendor</h5>
                <p className="text-gray-400">
                  {collection?.vendor.name},{collection?.vendor.phone}
                </p>
              </div>
            </div>
            <div className="col-span-12">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl">Description</h5>
                <div className="">
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: collection?.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCollectionDetail;
