import { gql } from "@apollo/client";

export const GET_SINGLE_PRODUCT_ID = gql`
  query MyQuery($id: Int!) {
    products_by_pk(id: $id) {
      name
      id
      description
      price
      sub_title
      fk_vendor_id
      fk_product_brand_id
      fk_product_category_id
      fk_product_subcategory_id
      fk_product_activity_id
      fk_product_collection_id
      fk_product_type_id
      fk_body_type_id
      promotion
      disabled
      product_variations {
        variation_color {
          id
          name
        }
      }
      product_image_url
    }
    sizes {
      id
      name
    }

    product_categories {
      id
      name
    }
    product_subcategories {
      id
      name
    }
    body_types {
      id
      name
    }
    vendors {
      id
      name
    }
    product_activities {
      id
      name
    }
    product_collections {
      id
      name
    }
    product_types {
      id
      name
    }
    product_brands {
      id
      name
    }
  }
`;
