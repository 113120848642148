import { gql } from "@apollo/client";

export const GET_ONE_PRODUCT_ACTIVITY = gql`
  query MyQuery($id: Int!) {
    product_activities_by_pk(id: $id) {
      activity_image_url
      disabled
      id
      name
      status
    }
  }
`;
