import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_ACTIVITY = gql`
  mutation MyMutation($id: Int!, $name: String!, $activity_image_url: String!) {
    update_product_activities_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, activity_image_url: $activity_image_url }
    ) {
      id
      name
      activity_image_url
      status
    }
  }
`;
