import { gql } from "@apollo/client";

export const GET_ALL_ORDER_ITEMS = gql`
  query MyQuery {
    order_items(order_by: { id: desc }) {
      variation_color {
        id
        name
        color_code
      }
      qty
      totalPrice

      product {
        id
        name
        price
      }
      size {
        id
        name
      }
      product_variation {
        id
        name
        product_image_url
        price
      }
      id
      order {
        id
        totalAmount
        payment_method
        status
        created_at
        updated_at
        user {
          id
          name
          phone
        }
      }
    }
  }
`;
