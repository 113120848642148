import { gql } from "@apollo/client";

export const CREATE_BLOG = gql`
  mutation MyMutation(
    $blog_image_url: String!
    $name: String!
    $description: String!
  ) {
    insert_blogs(
      objects: {
        blog_image_url: $blog_image_url
        name: $name
        description: $description
      }
    ) {
      returning {
        id
        name
        description
        blog_image_url
      }
    }
  }
`;
