import React from "react";
import { Header } from "../components";
import { Form, Formik } from "formik";
import { ActionButton, IconButton, SelectBox } from "../anh";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { IMAGE_TYPE } from "../config/common";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { useStateContext } from "../contexts/ContextProvider";
import { GET_ALL_PRODUCT_VARIATION } from "../graphql/queries/getAllProductVariation";
import { ADD_PRODUCT_IMAGE } from "../graphql/mutations/addProductImage";
import { useNavigate, useParams } from "react-router-dom";
import { uploadImageService } from "../lib/uploadImage";
import { GET_SINGLE_PRODUCT } from "../graphql/queries/getOneProduct";

const CreateProductImages = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const productId = parseInt(id);
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = React.useState("");
  const [selected, setSelected] = React.useState({});
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [formData, setFormData] = React.useState({
    product_image_url: "",
  });
  //GET ALL PRODUCT VARIATIONS
  const [loadProductVariation, getProductVariation] = useLazyQuery(
    GET_ALL_PRODUCT_VARIATION
  );
  const product_variations =
    Array.isArray(getProductVariation?.data?.product_variations) &&
    getProductVariation?.data?.product_variations.length > 0
      ? getProductVariation?.data?.product_variations
      : [];
  React.useEffect(() => {
    loadProductVariation({
      variables: {
        fk_product_id: productId,
      },
    });
  }, [loadProductVariation, productId]);
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log("result", result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        product_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  //Add Product Image
  const [add_product_images] = useMutation(ADD_PRODUCT_IMAGE, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Add Image Successfully!");
      navigate(-1);
    },
    refetchQueries: () => [
      {
        query: GET_SINGLE_PRODUCT,
      },
    ],
  });
  return (
    <div className="p-8 ">
      <Header title={"Create Product Images"} category="Product Images" />
      <ActionButton
        color={"#fff"}
        borderRadius={8}
        bgColor={currentColor}
        text={"Back"}
        onClick={() => navigate(-1)}
      />
      <div className="mt-4 flex flex-row justify-center">
        <div className="p-8 bg-white rounded dark:bg-secondary-dark-bg w-5/6 shadow">
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setErrors }) => {
              try {
                if (imageFile && imageFileUrl) {
                  await uploadImageService(imageFileUrl, imageFile);
                }

                await add_product_images({
                  variables: {
                    fk_product_id: productId,
                    fk_variation_id: selected?.id,
                    product_image_url: formData.product_image_url,
                  },
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleSubmit,
              handleBlur,
              handleChange,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="upload"
                    className="cursor-pointer border-2 w-full h-[400px]  rounded border-dashed dark:border-gray-400 flex flex-col justify-center items-center relative"
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="dark:text-gray-400 text-white">
                            Click here to Upload
                          </span>
                        </div>
                      </>
                    )}
                  </label>
                  <p className="text-center text-gray-400">
                    Width 60px and Height 60px
                  </p>
                </div>
                <div className="mb-3">
                  <h4 className="mb-2 dark:text-white text-black">Name</h4>
                  <SelectBox
                    name="Select Variation"
                    data={product_variations}
                    setSelected={setSelected}
                    selected={selected}
                  />
                </div>
                <div className="">
                  <ActionButton
                    disabled={isSubmitting}
                    type="submit"
                    text={isSubmitting ? "Creating.." : "Save"}
                    bgColor={currentColor}
                    width={"full"}
                    color="#fff"
                    borderRadius={8}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateProductImages;
