import React from "react";
import { Header } from "../components";
import { ActionButton, IconButton, TextField } from "../anh";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { IMAGE_TYPE } from "../config/common";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { uploadImageService } from "../lib/uploadImage";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ONE_PRODUCT_ACTIVITY } from "../graphql/queries/getOneProductActivity";
import { UPDATE_PRODUCT_ACTIVITY } from "../graphql/mutations/updateProductActivity";
import { GET_ALL_PRODUCT_ACTIVITY } from "../graphql/queries/getAllProductActivity";

const UpdateProductActivity = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const activityId = parseInt(id);
  const formikRef = React.useRef();
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [formData, setFormData] = React.useState({
    activity_image_url: "",
  });
  const navigate = useNavigate();
  //Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        activity_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };
  //Get Detail Brand
  const [loadActivityData, getActivityData] = useLazyQuery(
    GET_ONE_PRODUCT_ACTIVITY
  );
  const activity = getActivityData?.data?.product_activities_by_pk
    ? getActivityData.data.product_activities_by_pk
    : null;

  React.useEffect(() => {
    if (activityId) {
      loadActivityData({
        variables: {
          id: activityId,
        },
      });
    }
  }, [activityId, loadActivityData]);
  React.useEffect(() => {
    if (activity) {
      formikRef.current.setFieldValue("name", activity?.name);
      setImageFile(activity?.activity_image_url ?? "");
      setImagePreview(activity?.activity_image_url ?? "");
      setFormData({
        activity_image_url: activity?.activity_image_url ?? "",
      });
    }
  }, [activity]);
  //Update Brand
  const [update_activity] = useMutation(UPDATE_PRODUCT_ACTIVITY, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Updated Activity Successfully");
      navigate("/activities");
    },
    refetchQueries: () => [
      {
        query: GET_ALL_PRODUCT_ACTIVITY,
      },
    ],
  });

  return (
    <div className="p-6">
      <Header title="Update Activity" category="Activity" />
      <div className="flex flex-row justify-center">
        <div className="p-8 bg-white dark:bg-secondary-dark-bg rounded shadow w-full lg:w-5/6">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            onSubmit={async (values) => {
              try {
                if (imageFile && imageFileUrl) {
                  await uploadImageService(imageFileUrl, imageFile);
                }
                await update_activity({
                  variables: {
                    id: activityId,
                    activity_image_url: formData.activity_image_url,
                    name: values.name,
                  },
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="mb-4">
                  <label
                    htmlFor="upload"
                    className="cursor-pointer border-2 w-full h-[400px]  rounded border-dashed dark:border-gray-400 flex flex-col justify-center items-center relative"
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="dark:text-gray-400 text-white">
                            Click here to Upload
                          </span>
                        </div>
                      </>
                    )}
                  </label>
                </div>
                <div className="mb-4">
                  <h4 className="mb-2 dark:text-white text-xl">Name</h4>
                  <TextField
                    name="name"
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    inputSize={"medium"}
                    placeholder={"Name"}
                    fullWidth
                  />
                </div>
                <ActionButton
                  type="submit"
                  disabled={isSubmitting}
                  text={isSubmitting ? "Updating" : "Save"}
                  bgColor={currentColor}
                  borderRadius={8}
                  width={"24"}
                  color={"#fff"}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductActivity;
