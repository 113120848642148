import React from "react";
import { Header } from "../components";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ONE_VARIATION_SIZE } from "../graphql/queries/getOneVariationSize";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { ActionButton, TextField } from "../anh";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { ADD_SIZE } from "../graphql/mutations/addSize";
import { CREATE_VARIATION_SIZE } from "../graphql/mutations/addVariatonSize";

const VariationDetail = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [addToggle, setAddToggle] = React.useState({
    size: false,
  });
  const { id } = useParams();
  const variationId = parseInt(id);
  const [loadVariationData, getVariationData] = useLazyQuery(
    GET_ONE_VARIATION_SIZE
  );
  const product_variation =
    getVariationData?.data?.product_variations_by_pk &&
    getVariationData?.data?.product_variations_by_pk
      ? getVariationData?.data?.product_variations_by_pk
      : null;

  const variation_sizes =
    Array.isArray(getVariationData?.data?.variation_sizes) &&
    getVariationData?.data?.variation_sizes.length > 0
      ? getVariationData?.data?.variation_sizes
      : [];
  const color = getVariationData?.data?.product_variations_by_pk
    ?.variation_color
    ? getVariationData.data.product_variations_by_pk.variation_color
    : null;
  React.useEffect(() => {
    loadVariationData({
      variables: {
        id: variationId,
      },
      fetchPolicy: "network-only",
    });
  }, [variationId, loadVariationData]);

  //Add Variation Size
  const [add_variation_size] = useMutation(CREATE_VARIATION_SIZE, {
    onCompleted: (result) => {
      toast.success("Add Size Successfully!");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  //add Size
  const [add_size] = useMutation(ADD_SIZE, {
    onCompleted: async (result) => {
      console.log(result);
      await add_variation_size({
        variables: {
          fk_variation_id: variationId,
          fk_variation_size_id: result.insert_sizes_one.id,
        },
      });
    },

    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: () => [
      {
        query: GET_ONE_VARIATION_SIZE,
      },
    ],
  });

  return (
    <div className="p-8">
      <Header title={"Variation Size Detail"} category={"Variation"} />
      <ActionButton
        color={"#fff"}
        borderRadius={8}
        bgColor={currentColor}
        text={"Back"}
        onClick={() => navigate(-1)}
      />
      <div className="bg-white mt-4 dark:bg-secondary-dark-bg p-6 rounded">
        <div className="flex flex-col gap-10">
          <div className="col-span-12">
            <div className="h-[500px]">
              <img
                className="h-full w-full object-contain"
                src={product_variation?.product_image_url}
                alt={"product_image"}
              />
            </div>
          </div>
          {/* Detail Data */}
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">ID</h5>
                <p className="text-gray-400">{product_variation?.id}</p>
              </div>
            </div>
            <div className="col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">Name</h5>
                <p className="text-gray-400">{product_variation?.name}</p>
              </div>
            </div>

            <div className="col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">
                  Variation Sizes
                </h5>
                <div className="flex flex-row items-center space-x-2">
                  {Array.isArray(variation_sizes) &&
                  variation_sizes.length > 0 ? (
                    <p className="text-gray-400">
                      {variation_sizes.map(({ size }) => size.name).join(" , ")}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <div className="flex flex-col space-y-2">
                <h5 className="text-xl text-black dark:text-white">
                  Variation Colors
                </h5>
                <div className="flex flex-row items-center space-x-2">
                  <div
                    style={{ background: color?.color_code }}
                    className="p-2 rounded text-white inline-block"
                  >
                    {color?.color_code}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Description */}
        <div className="mt-10">
          <h5 className="text-xl text-black dark:text-white mb-2">
            Description
          </h5>
          <p
            className="text-gray-400"
            dangerouslySetInnerHTML={{
              __html: product_variation?.description,
            }}
          />
        </div>
        <>
          {addToggle.size && (
            <Formik
              initialValues={{
                size: "",
              }}
              validationSchema={Yup.object().shape({
                size: Yup.string().max(255).required("Color required!"),
              })}
              onSubmit={async (values, { resetForm }) => {
                try {
                  await add_size({
                    variables: {
                      name: values.size,
                    },
                  });
                  resetForm();
                } catch (error) {
                  console.log(error.message);
                }
              }}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                errors,
                isSubmitting,
                isValid,
                touched,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-10">
                  <h4 className="dark:text-white">Size</h4>
                  <div className=" flex flex-row space-x-2 items-center">
                    <TextField
                      disabled={isSubmitting}
                      placeholder="Size"
                      inputSize={"medium"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="size"
                      id={"size"}
                      value={values.size}
                      error={
                        touched.size && errors.size && Boolean(errors.size)
                      }
                      helperText={touched.size && errors.size && errors.size}
                    />

                    <ActionButton
                      type="submit"
                      text="Save"
                      disabled={isSubmitting || !isValid}
                      bgColor={currentColor}
                      color={"#fff"}
                      borderRadius={8}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
        <div className="mt-10">
          <h4 className="text-xl dark:text-white">Actions</h4>
          <div className="mt-4 flex space-x-2 flex-row ">
            <ActionButton
              onClick={() =>
                setAddToggle({
                  color: false,
                  size: !addToggle.size,
                })
              }
              text="Add Size"
              bgColor={currentColor}
              color={"#fff"}
              borderRadius={8}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariationDetail;
