import {
  Activities,
  Blogs,
  Brands,
  Collections,
  CreateProduct,
  Dashboard,
  Orders,
  ProductCategory,
  ProductDetail,
  Products,
  UpdateProduct,
  Users,
  Vendors,
  CreateBrand,
  UpdateBrand,
  UpdateProductCategory,
  UpdateProductActivity,
  UpdateProductCollection,
  CreateProductCollection,
  ProductCollectionDetail,
  UpdateBlog,
  CreateBlog,
  BlogDetail,
  CreateProductCategory,
  CreateActivity,
  CreateProductVariation,
  VariationDetail,
  CreateProductImages,
  CreateSubCategory,
  SubCategories,
  UpdateSubCategory,
  CreateProductModel,
  ProductModel,
  UpdateProductModel,
  VariationColors,
  CreateColor,
  UpdateVariationColor,
} from "../pages";
import MainLayout from "../layout/MainLayout";
import UpdateProductVariation from "../pages/UpdateProductVariation";
import VariationSizes from "../pages/VariationSizes";
import UpdateVariationSize from "../pages/UpdateVariationSize";
import AddVariationSize from "../pages/AddVariationSize";
import DailyReport from "../pages/DailyReport";
import DailyReportDetail from "../pages/DailyReportDetail";

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/products/:id/add-images",
      element: <CreateProductImages />,
    },
    {
      path: "/products/:id/add-variation",
      element: <CreateProductVariation />,
    },
    {
      path: "/products/edit-variation/:id",
      element: <UpdateProductVariation />,
    },
    {
      path: "/products/variation/:id",
      element: <VariationDetail />,
    },
    {
      path: "/products/:id",
      element: <ProductDetail />,
    },
    {
      path: "/products/add",
      element: <CreateProduct />,
    },
    {
      path: "/products/edit/:id",
      element: <UpdateProduct />,
    },
    {
      path: "/users",
      element: <Users />,
    },
    {
      path: "/orders",
      element: <Orders />,
    },
    {
      path: "/activities",
      element: <Activities />,
    },
    {
      path: "/activities/add",
      element: <CreateActivity />,
    },
    {
      path: "/activities/edit/:id",
      element: <UpdateProductActivity />,
    },
    {
      path: "/vendors",
      element: <Vendors />,
    },
    {
      path: "/collections",
      element: <Collections />,
    },
    {
      path: "/collections/add",
      element: <CreateProductCollection />,
    },
    {
      path: "/collections/:id",
      element: <ProductCollectionDetail />,
    },
    {
      path: "/collections/edit/:id",
      element: <UpdateProductCollection />,
    },
    {
      path: "/brands",
      element: <Brands />,
    },
    {
      path: "/brands/add",
      element: <CreateBrand />,
    },
    {
      path: "/brands/edit/:id",
      element: <UpdateBrand />,
    },
    {
      path: "/categories/add",
      element: <CreateProductCategory />,
    },
    {
      path: "/categories",
      element: <ProductCategory />,
    },
    {
      path: "/categories/edit/:id",
      element: <UpdateProductCategory />,
    },
    {
      path: "/subcategories",
      element: <SubCategories />,
    },
    {
      path: "/variation-colors",
      element: <VariationColors />,
    },
    {
      path: "/variation-sizes",
      element: <VariationSizes />,
    },
    {
      path: "/variation-sizes/edit/:id",
      element: <UpdateVariationSize />,
    },
    {
      path: "/variation-sizes/add",
      element: <AddVariationSize />,
    },
    {
      path: "/daily-report",
      element: <DailyReport />,
    },
    {
      path: "/daily-report/:id",
      element: <DailyReportDetail />,
    },
    {
      path: "/variation-colors/add",
      element: <CreateColor />,
    },
    {
      path: "/variation-colors/edit/:id",
      element: <UpdateVariationColor />,
    },
    {
      path: "/subcategories/edit/:id",
      element: <UpdateSubCategory />,
    },
    {
      path: "/subcategories/add",
      element: <CreateSubCategory />,
    },
    {
      path: "/product-models",
      element: <ProductModel />,
    },
    {
      path: "/product-models/add",
      element: <CreateProductModel />,
    },
    {
      path: "/product-models/edit/:id",
      element: <UpdateProductModel />,
    },
    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/blogs/:id",
      element: <BlogDetail />,
    },
    {
      path: "/blogs/edit/:id",
      element: <UpdateBlog />,
    },
    {
      path: "/blogs/add",
      element: <CreateBlog />,
    },
  ],
};

export default MainRoutes;
