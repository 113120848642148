import { gql } from "@apollo/client";

export const ADD_BRAND = gql`
  mutation MyMutation($brand_image_url: String!, $name: String!) {
    insert_product_brands(
      objects: { brand_image_url: $brand_image_url, name: $name }
    ) {
      returning {
        brand_image_url
        name
      }
    }
  }
`;
