import { gql } from "@apollo/client";

export const DELETE_VARIATION_SIZE = gql`
  mutation MyMutation($fk_variation_id: Int!, $fk_variation_size_id: Int!) {
    delete_variation_sizes(
      where: {
        fk_variation_id: { _eq: $fk_variation_id }
        fk_variation_size_id: { _eq: $fk_variation_size_id }
      }
    ) {
      returning {
        id
        fk_variation_size_id
        fk_variation_id
      }
    }
  }
`;
