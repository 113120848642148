import React from "react";
import Header from "../components/Header";
import { IconButton } from "../anh";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_COLORS } from "../graphql/queries/getAllColors";

const VariationColors = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [loadColors, getColors] = useLazyQuery(GET_ALL_COLORS);
  React.useEffect(() => {
    loadColors({
      variables: {},
      fetchPolicy: "network-only",
    });
  }, [loadColors]);

  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product Colors"} category="Colors" />
      <Link
        to={"/variation-colors/add"}
        style={{ background: currentColor }}
        className="text-white outline-none px-3 py-2 rounded-lg"
      >
        Add Color
      </Link>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Color
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {Array.isArray(getColors?.data?.variation_colors) &&
                getColors?.data.variation_colors.length > 0 &&
                getColors?.data.variation_colors.map((data) => (
                  <tr key={data.id}>
                    <td className="px-6 py-4">{data.id}</td>

                    <td className="px-6 py-4">{data.name ? data.name : "#"}</td>
                    <td className="px-6 py-4">
                      <div
                        className="w-10 h-10 rounded-full"
                        style={{ background: data.color_code }}
                      />
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-row">
                        <IconButton
                          onClick={() =>
                            navigate(`/variation-colors/edit/${data.id}`)
                          }
                          icon={
                            <PencilSquareIcon
                              style={{ color: currentColor }}
                              className="w-6 h-6"
                            />
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VariationColors;
