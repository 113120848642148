import React from "react";
import Header from "../components/Header";
import { IconButton } from "../anh";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import clsx from "clsx";
import { toast } from "react-toastify";
import { GET_ALL_BLOGS } from "../graphql/queries/getAllBlog";
import { DELETE_BLOG } from "../graphql/mutations/deleteBlog";

const Blogs = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [loadBlog, getBlogData] = useLazyQuery(GET_ALL_BLOGS);
  const blogs =
    Array.isArray(getBlogData?.data?.blogs) &&
    getBlogData?.data?.blogs.length > 0
      ? getBlogData?.data?.blogs
      : [];
  React.useEffect(() => {
    loadBlog({
      variables: {},
      fetchPolicy: "network-only",
    });
  }, [loadBlog]);

  //Delete Product Brand
  const [delete_blog] = useMutation(DELETE_BLOG, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Delete Blog Successfully");
      navigate("/blogs");
    },
  });
  const handleDeleteCollection = async (deleteId) => {
    await delete_blog({
      variables: {
        id: deleteId,
      },
    });
  };
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product Blog"} category="Blog" />
      <Link
        to={"/blogs/add"}
        style={{ background: currentColor }}
        className="text-white outline-none px-3 py-2 rounded-lg"
      >
        Add Blog
      </Link>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {blogs.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4">{item.id}</td>
                  <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                    <div className="relative h-14 w-14">
                      <img
                        src={item.blog_image_url}
                        alt={item.name}
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </th>

                  <td className="px-6 py-4">{item.name}</td>
                  {/* <td className="px-6 py-4">{item.vendor.name}</td> */}
                  <td className="px-6 py-4">
                    <span
                      className={clsx("text-white px-2 rounded", {
                        "bg-green-400": item.status,
                        "bg-red-400": !item.status,
                      })}
                    >
                      {" "}
                      {item.status ? "Active" : "Pending"}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-row items-center">
                      <Link
                        to={`/blogs/${item.id}`}
                        className="px-3 py-2 text-white mr-2 rounded"
                        style={{ background: currentColor }}
                      >
                        Detail
                      </Link>
                      <IconButton
                        onClick={() => handleDeleteCollection(item.id)}
                        icon={<TrashIcon className="w-6 h-6" />}
                      />
                      <IconButton
                        onClick={() => navigate(`/blogs/edit/${item.id}`)}
                        icon={
                          <PencilSquareIcon
                            style={{ color: currentColor }}
                            className="w-6 h-6"
                          />
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
