import { gql } from "@apollo/client";

export const DELETE_BLOG = gql`
  mutation MyMutation($id: Int!) {
    delete_blogs_by_pk(id: $id) {
      id
      name
    }
  }
`;
