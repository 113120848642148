import { gql } from "@apollo/client";

export const ADD_PRODUCT_CATEGORY = gql`
  mutation MyMutation($name: String!, $category_image_url: String!) {
    insert_product_categories(
      objects: { name: $name, category_image_url: $category_image_url }
    ) {
      returning {
        id
        name
        category_image_url
      }
    }
  }
`;
