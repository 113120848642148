import React from "react";
import Header from "../components/Header";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_ORDERS } from "../graphql/queries/getAllOrders";
import clsx from "clsx";
import { Link } from "react-router-dom";

const DailyReport = () => {
  const [loadOrder, getOrderData] = useLazyQuery(GET_ALL_ORDERS);
  const [data, setData] = React.useState([]);
  const [category, setCategory] = React.useState("All");
  console.log(getOrderData);
  React.useEffect(() => {
    loadOrder({
      variables: {},
    });
  }, [loadOrder]);
  React.useEffect(() => {
    if (getOrderData?.data?.orders) {
      setData(getOrderData?.data?.orders);
    }
  }, [getOrderData?.data?.orders]);

  const handleFilter = (value) => {
    setCategory(value);
  };

  React.useEffect(() => {
    if (category === "All") {
      setData(getOrderData?.data?.orders);
    } else {
      const updateData = getOrderData?.data?.orders.filter(
        (order) => order.status === category.toUpperCase()
      );
      setData(updateData);
    }
  }, [category, getOrderData?.data?.orders]);
  return (
    <div className="p-8 dark:text-white">
      <Header title={" Daily Report"} category="Report" />
      <div className="flex flex-row border-b border-gray-400 items-center">
        {["All", "Pending", "Delivery", "Complete"].map((value, index) => (
          <div
            key={index}
            onClick={() => handleFilter(value)}
            className={clsx("px-3 py-2  mx-2 cursor-pointer", {
              "border-red-500 border-b-2": value === category,
            })}
          >
            {value}
          </div>
        ))}
      </div>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>

                {/* <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Username
                </th> */}
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Phone
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  TotalPrice
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Payment Method
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {Array.isArray(data) &&
                data.length > 0 &&
                data.map((item, index) => <TableRow key={index} item={item} />)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DailyReport;

export const TableRow = ({ item }) => {
  return (
    <tr>
      <td className="px-6 py-4">{item.id}</td>
      {/* <td className="px-6 py-4">{item.shipping_address.name}</td> */}
      <td className="px-6 py-4">{item.shipping_address.phone}</td>
      <td className="px-6 py-4">{item.shipping_address.address}</td>
      <td className="px-6 py-4">{item.totalAmount}</td>
      <td className="px-6 py-4">
        {item.payment_method === "cod"
          ? "Cash On Delivery"
          : item.payment_method === "mc"
          ? "Master/Visa Card"
          : item.payment_method === "kbz"
          ? "KBZ Pay"
          : item.payment_method === "cb"
          ? "CB Pay"
          : ""}
      </td>

      <td className="px-6 py-4">
        <span
          className={clsx("text-white rounded px-1", {
            "bg-red-500": item.status === "PENDING",
            "bg-blue-500": item.status === "DELIVERY",
            "bg-green-500": item.status === "COMPLETE",
          })}
        >
          {item.status}
        </span>
      </td>
      <td className="px-6 py-4 flex flex-row space-x-2">
        <Link to={`/daily-report/${item.id}`} className="text-red-600">
          Detail
        </Link>
      </td>
    </tr>
  );
};
