import { gql } from "@apollo/client";

export const GET_ONE_VARIATION_SIZE = gql`
  query MyQuery($id: Int!) {
    product_variations_by_pk(id: $id) {
      fk_product_id
      id
      name
      description
      disabled
      price
      product_image_url
      variation_color {
        id
        color_code
        name
      }
    }
    variation_sizes(where: { fk_variation_id: { _eq: $id } }) {
      size {
        name
        id
        created_at
        updated_at
      }
    }
  }
`;
