import { gql } from "@apollo/client";

export const GET_ALL_VENDORS = gql`
  query MyQuery {
    vendors {
      disabled
      created_at
      email
      id
      name
      phone
    }
  }
`;
