import { gql } from "@apollo/client";

export const GET_ALL_BLOGS = gql`
  query MyQuery {
    blogs {
      id
      name
      description
      created_at
      blog_image_url
      updated_at
      status
    }
  }
`;
