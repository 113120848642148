import { gql } from "@apollo/client";

export const GET_ALL_PRODUCT_ACTIVITY = gql`
  query MyQuery {
    product_activities {
      id
      name
      status
      activity_image_url
      disabled
    }
  }
`;
