import { gql } from "@apollo/client";

export const ADD_PRODUCT_ACTIVITY = gql`
  mutation MyMutation($activity_image_url: String!, $name: String!) {
    insert_product_activities(
      objects: { activity_image_url: $activity_image_url, name: $name }
    ) {
      returning {
        activity_image_url
        id
        name
      }
    }
  }
`;
