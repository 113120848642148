import { gql } from "@apollo/client";

export const GET_ONE_PRODUCT_CATEGORY = gql`
  query MyQuery($id: Int!) {
    product_categories_by_pk(id: $id) {
      id
      name
      category_image_url
    }
  }
`;
