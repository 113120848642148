import { gql } from "@apollo/client";

export const ADD_SUBCATEGORY = gql`
  mutation MyMutation($fk_category_id: Int!, $name: String!) {
    insert_product_subcategories_one(
      object: { fk_category_id: $fk_category_id, name: $name }
    ) {
      name
      id
    }
  }
`;
