import React from "react";
import Header from "../components/Header";
import { ActionButton, IconButton, MutipleSelectBox, TextField } from "../anh";
import { useStateContext } from "../contexts/ContextProvider";
import { Form, Formik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_PRODUCT } from "../graphql/mutations/addProduct";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { IMAGE_TYPE } from "../config/common";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { uploadImageService } from "../lib/uploadImage";
import { GET_CREATE_PRODUCT } from "../graphql/queries/getCreateProduct";
import { SelectBox } from "../anh";
import RichTextEditor from "react-rte";
import MyRichTextEditor from "../components/MyRichTextEditor";
import * as Yup from "yup";
import clsx from "clsx";
import { GET_SUBCATEGORY_BY_CATID } from "../graphql/queries/getSubCategoryByCatId";
import { GET_PRODUCT_TYPES_BY_ID } from "../graphql/queries/getProductTypeById";
import { ADD_AUTO_VARIATION } from "../graphql/mutations/addAutoVariation";
import { ADD_PRODUCT_IMAGE } from "../graphql/mutations/addProductImage";
import { ADD_VARIATION_SIZE } from "../graphql/mutations/addVariationSize";
const CreateProduct = () => {
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [formData, setFormData] = React.useState({
    product_image_url: "",
    description: "",
  });
  const [description, setDescription] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const [variationSizes, setVariationSize] = React.useState([]);
  const [customError, setCustomError] = React.useState({
    category: "",
    description: "",
    subcategory: "",
    gender: "",
    activity: "",
    collection: "",
    product_type: "",
    brand: "",
    size: "",
    vendor: "",
    image: "",
  });
  const [chooseColor, setChooseColor] = React.useState();
  const [colorToggle, setColorToggle] = React.useState(false);
  const [brandSelect, setBrandSelect] = React.useState({});
  const [categorySelect, setCategorySelect] = React.useState({});
  const [subcategorySelect, setSubCategorySelect] = React.useState({});
  const [productTypeSelect, setProductTypeSelect] = React.useState({});
  const [activitySelect, setActivitySelect] = React.useState({});
  const [collectionSelect, setCollectionSelect] = React.useState({});
  const [vendorSelect, setVendorSelect] = React.useState({});
  const [genderSelect, setGenderSelect] = React.useState({});
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  //Get SubCategory By Category Id
  const [loadSelectSubCat, getSelectSubCat] = useLazyQuery(
    GET_SUBCATEGORY_BY_CATID
  );
  const subcategories =
    Array.isArray(getSelectSubCat?.data?.product_subcategories) &&
    getSelectSubCat?.data?.product_subcategories.length > 0
      ? getSelectSubCat?.data?.product_subcategories
      : [];
  React.useEffect(() => {
    if (categorySelect.id) {
      loadSelectSubCat({
        variables: {
          fk_category_id: categorySelect.id,
        },
      });
    }
  }, [categorySelect.id, loadSelectSubCat]);

  //Get Product Type By SubCategory Id
  const [loadProductTypes, getProductTypes] = useLazyQuery(
    GET_PRODUCT_TYPES_BY_ID
  );
  const product_types =
    Array.isArray(getProductTypes?.data?.product_types) &&
    getProductTypes?.data?.product_types.length > 0
      ? getProductTypes?.data?.product_types
      : [];
  React.useEffect(() => {
    if (subcategorySelect.id) {
      loadProductTypes({
        variables: {
          fk_subcategory_id: subcategorySelect.id,
        },
      });
    }
  }, [subcategorySelect.id, loadProductTypes]);

  //Get All Select Box Data
  const [loadSelectBoxData, getSelectBoxData] =
    useLazyQuery(GET_CREATE_PRODUCT);
  const categories =
    Array.isArray(getSelectBoxData?.data?.product_categories) &&
    getSelectBoxData?.data?.product_categories.length > 0
      ? getSelectBoxData?.data?.product_categories
      : [];
  const activities =
    Array.isArray(getSelectBoxData?.data?.product_activities) &&
    getSelectBoxData?.data?.product_activities.length > 0
      ? getSelectBoxData?.data?.product_activities
      : [];
  const collections =
    Array.isArray(getSelectBoxData?.data?.product_collections) &&
    getSelectBoxData?.data?.product_collections.length > 0
      ? getSelectBoxData?.data?.product_collections
      : [];
  const brands =
    Array.isArray(getSelectBoxData?.data?.product_brands) &&
    getSelectBoxData?.data?.product_brands.length > 0
      ? getSelectBoxData?.data?.product_brands
      : [];
  const vendors =
    Array.isArray(getSelectBoxData?.data?.vendors) &&
    getSelectBoxData?.data?.vendors.length > 0
      ? getSelectBoxData?.data?.vendors
      : [];
  const body_types =
    Array.isArray(getSelectBoxData?.data?.body_types) &&
    getSelectBoxData?.data?.body_types.length > 0
      ? getSelectBoxData?.data?.body_types
      : [];
  const sizes =
    Array.isArray(getSelectBoxData?.data?.sizes) &&
    getSelectBoxData?.data?.sizes.length > 0
      ? getSelectBoxData?.data?.sizes
      : [];
  const variation_colors =
    Array.isArray(getSelectBoxData?.data?.variation_colors) &&
    getSelectBoxData?.data?.variation_colors.length > 0
      ? getSelectBoxData?.data?.variation_colors
      : [];

  React.useEffect(() => {
    loadSelectBoxData({
      variables: {},
    });
  }, [loadSelectBoxData]);

  //Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log("result", result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        product_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };
  //Auto Create Product Images
  const [add_image] = useMutation(ADD_PRODUCT_IMAGE, {
    onError: (err) => {
      console.log(err.message);
      toast.error(err.message);
    },
    onCompleted: ({ insert_product_variations_one }) => {
      toast.success("New Product has been added");
      navigate("/products");
    },
  });

  //Auto Create Variation Sizes
  const [add_variation_size] = useMutation(ADD_VARIATION_SIZE, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      console.log(result);
    },
  });

  //Auto Create Variation
  const [add_variation] = useMutation(ADD_AUTO_VARIATION, {
    onError: (err) => {
      console.log(err.message);
    },
    onCompleted: ({ insert_product_variations_one }) => {
      add_image({
        variables: {
          fk_variation_id: insert_product_variations_one.id,
          fk_product_id: insert_product_variations_one.fk_product_id,
          product_image_url: insert_product_variations_one.product_image_url,
        },
      });

      Array.isArray(variationSizes) &&
        variationSizes.length > 0 &&
        variationSizes.map((size) =>
          add_variation_size({
            variables: {
              fk_variation_id: insert_product_variations_one.id,
              fk_variation_size_id: size.id,
            },
          })
        );
    },
  });

  //Create Product
  const [add_product] = useMutation(ADD_PRODUCT, {
    onError: (err) => {
      console.log(err.message);
      toast.error(err.message);
    },
    onCompleted: ({ insert_products_one }) => {
      add_variation({
        variables: {
          fk_product_id: insert_products_one?.id,
          name: insert_products_one?.name,
          price: insert_products_one?.price,
          description: insert_products_one?.description,
          product_image_url: insert_products_one?.product_image_url,
          fk_variation_color_id: chooseColor?.id,
          store: 10,
        },
      });
    },
  });
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Add Product"} category={"Product"} />
      <Link
        to={"/products"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="">
        <div className=" p-8 bg-white dark:bg-secondary-dark-bg rouned shadow">
          <Formik
            initialValues={{
              product_name: "",
              sub_title: "",
              price: "",
            }}
            validationSchema={Yup.object().shape({
              product_name: Yup.string()
                .max(255)
                .required("product name required!"),
              sub_title: Yup.string()
                .max(255)
                .required("product type required!"),
              price: Yup.string().max(255).required("price required!"),
            })}
            onSubmit={async (values) => {
              try {
                if (!imageFile && !imageFileUrl) {
                  setCustomError({
                    ...currentColor,
                    image: "product image is required",
                  });
                } else if (!categorySelect?.id) {
                  setCustomError({
                    ...customError,
                    category: "category is required",
                  });
                } else if (!subcategorySelect?.id) {
                  setCustomError({
                    ...customError,
                    subcategory: "sub category is required",
                  });
                } else if (!productTypeSelect?.id) {
                  setCustomError({
                    ...customError,
                    product_type: "Product Type is required",
                  });
                } else if (!genderSelect?.id) {
                  setCustomError({
                    ...customError,
                    gender: "Body type is required",
                  });
                } else if (!activitySelect?.id) {
                  setCustomError({
                    ...customError,
                    activity: "product activity is required",
                  });
                } else if (!collectionSelect?.id) {
                  setCustomError({
                    ...customError,
                    collection: "Collection is required",
                  });
                } else if (!brandSelect?.id) {
                  setCustomError({
                    ...customError,
                    brand: "Product Brand is required",
                  });
                } else if (!vendorSelect?.id) {
                  setCustomError({
                    ...customError,
                    vendor: "Vendor Type is required",
                  });
                } else if (!formData?.description) {
                  setCustomError({
                    ...customError,
                    description: "Description is required",
                  });
                } else {
                  await uploadImageService(imageFileUrl, imageFile);

                  await add_product({
                    variables: {
                      name: values.product_name,
                      description: formData.description,
                      sub_title: values.sub_title,
                      fk_product_collection_id: collectionSelect?.id,
                      fk_body_type_id: genderSelect?.id,
                      fk_vendor_id: vendorSelect?.id,
                      fk_product_brand_id: brandSelect?.id,
                      fk_product_activity_id: activitySelect?.id,
                      fk_product_category_id: categorySelect?.id,
                      fk_product_type_id: productTypeSelect?.id,
                      fk_product_subcategory_id: subcategorySelect?.id,
                      product_image_url: formData?.product_image_url,
                      price: values.price,
                      status: true,
                    },
                  });
                }
              } catch (error) {
                toast.error(error.message);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              isValid,
            }) => (
              <Form className="w-full" noValidate onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-4 lg:gap-8">
                  {/* Product Image */}
                  <div className="col-span-12">
                    <label
                      htmlFor="upload"
                      className={clsx(
                        "cursor-pointer border-2 w-full h-[400px] rounded border-dashed relative  flex flex-col justify-center items-center",
                        {
                          "border-red-600": customError.image,
                          "border-gray-400": !customError.image,
                        }
                      )}
                    >
                      {imagePreview ? (
                        <>
                          <img
                            src={imagePreview}
                            className="h-full w-full object-contain"
                            alt="Brand_Image"
                          />
                          <IconButton
                            onClick={() => setImagePreview()}
                            className={"absolute top-0 right-0"}
                            icon={<XMarkIcon className="w-5 h-5" />}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            id="upload"
                            type="file"
                            onChange={chooseImage}
                            hidden
                          />
                          <div className="flex flex-row items-center">
                            <CloudArrowUpIcon
                              style={{ color: currentColor }}
                              className="mr-2 w-8 h-8"
                            />{" "}
                            <span className="text-gray-400">
                              Click here to Upload
                            </span>
                          </div>

                          {customError.image && (
                            <h4 className="text-xl text-red-600 mt-6 capitalize">
                              {customError.image}
                            </h4>
                          )}
                        </>
                      )}
                    </label>
                    <p className="text-center text-gray-400">
                      Width 210px and Height 275px
                    </p>
                  </div>

                  {/* Product Name */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Name
                      </h4>
                      <TextField
                        id="product_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="product_name"
                        type={"text"}
                        inputSize={"medium"}
                        fullWidth
                        placeholder={"Name"}
                        error={
                          touched.product_name &&
                          errors.product_name &&
                          Boolean(errors.product_name)
                        }
                        helperText={
                          touched.product_name &&
                          errors.product_name &&
                          errors.product_name
                        }
                      />
                    </div>
                  </div>
                  {/* Product Type */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Sub Title
                      </h4>
                      <TextField
                        type={"text"}
                        inputSize={"medium"}
                        fullWidth
                        id="sub_title"
                        name="sub_title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={"Sub Title"}
                        error={
                          touched.sub_title &&
                          errors.sub_title &&
                          Boolean(errors.sub_title)
                        }
                        helperText={
                          touched.sub_title &&
                          errors.sub_title &&
                          errors.sub_title
                        }
                      />
                    </div>
                  </div>
                  {/* Price */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Price
                      </h4>
                      <div className="rounded">
                        <TextField
                          type={"text"}
                          inputSize={"medium"}
                          fullWidth
                          id="price"
                          name="price"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={"Price"}
                          error={
                            touched.price &&
                            errors.price &&
                            Boolean(errors.price)
                          }
                          helperText={
                            touched.price && errors.price && errors.price
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* Categories */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Category
                      </h4>
                      <div className="rounded">
                        <SelectBox
                          name={"category"}
                          selected={categorySelect}
                          setSelected={setCategorySelect}
                          data={categories}
                          errorMsg={customError.category}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Sub Categories */}
                  {categorySelect.id && (
                    <div className="col-span-12 lg:col-span-6">
                      <div className="">
                        <h4 className="text-gray-600 dark:text-white mb-2">
                          Sub Category
                        </h4>
                        <div className=" rounded">
                          <SelectBox
                            name={"SubCategory"}
                            selected={subcategorySelect}
                            setSelected={setSubCategorySelect}
                            data={subcategories}
                            errorMsg={customError.subcategory}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Product Types  */}
                  {subcategorySelect.id && (
                    <div className="col-span-12 lg:col-span-6">
                      <div className="">
                        <h4 className="text-gray-600 dark:text-white mb-2">
                          Product Types
                        </h4>
                        <div className=" rounded">
                          <SelectBox
                            name={"SubCategory"}
                            selected={productTypeSelect}
                            setSelected={setProductTypeSelect}
                            data={product_types}
                            errorMsg={customError.product_type}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Product Variation Sizes */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="dark:text-white mb-2 text-black">
                        Variation Size
                      </h4>
                      <MutipleSelectBox
                        name={"Variation Sizes"}
                        selected={variationSizes}
                        setSelected={setVariationSize}
                        data={sizes}
                      />
                      {customError.size && (
                        <span className="text-red-600">{customError.size}</span>
                      )}
                    </div>
                  </div>

                  {/* Product Variation Colors */}
                  <div className="relative col-span-6 w-full">
                    <h4 className="dark:text-white mb-2 text-black ">
                      Variation Colors{" "}
                    </h4>
                    <div className="border p-3 rounded text-black dark:text-white">
                      {chooseColor?.color_code ? (
                        <span className="uppercase">
                          {chooseColor?.color_code}
                        </span>
                      ) : (
                        <span className="text-gray-400">Colors</span>
                      )}
                    </div>
                    <div
                      onClick={() => setColorToggle((prev) => !prev)}
                      className="cursor-pointer p-2 rounded-full hover:bg-black disabled:hover:bg-inherit disabled:cursor-not-allowed disabled:text-gray-500 dark:disabled:text-gray-800 hover:bg-opacity-10 ring-red-500 focus:ring-4 absolute top-9 inline-block  right-0"
                    >
                      {colorToggle ? (
                        <ChevronUpIcon className="w-6 h-6 text-black dark:text-white  cursor-pointer" />
                      ) : (
                        <ChevronDownIcon className="w-6 h-6 text-black dark:text-white  cursor-pointer" />
                      )}
                    </div>
                    {colorToggle && (
                      <div className="flex flex-col p-2 z-40 bg-main-dark-bg rounded absolute top-20 w-full h-[300px] overflow-y-auto">
                        <div className="grid grid-cols-12 gap-2">
                          {Array.isArray(variation_colors) &&
                            variation_colors.length > 0 &&
                            variation_colors.map((color) => (
                              <div
                                onClick={() => setChooseColor(color)}
                                key={color.id}
                                className={clsx(
                                  "col-span-3 flex flex-col justify-center items-center p-8 rounded  cursor-pointer",
                                  {
                                    "border-2 border-red-500":
                                      chooseColor?.id === color.id,
                                  }
                                )}
                                style={{ background: color.color_code }}
                              >
                                <p className="text-white">{color.color_code}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Gender */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Gender
                      </h4>
                      <div className=" rounded">
                        <SelectBox
                          name={"Gender"}
                          selected={genderSelect}
                          setSelected={setGenderSelect}
                          data={body_types}
                          errorMsg={customError.gender}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Activities */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Activities
                      </h4>
                      <div className=" rounded">
                        <SelectBox
                          name={"Activities"}
                          selected={activitySelect}
                          setSelected={setActivitySelect}
                          data={activities}
                          errorMsg={customError.activity}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Collections */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Collections
                      </h4>
                      <div className=" rounded">
                        <SelectBox
                          name={"Collections"}
                          selected={collectionSelect}
                          setSelected={setCollectionSelect}
                          data={collections}
                          errorMsg={customError.collection}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Brands */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Brands
                      </h4>
                      <div className=" rounded">
                        <SelectBox
                          name={"Brands"}
                          selected={brandSelect}
                          setSelected={setBrandSelect}
                          data={brands}
                          errorMsg={customError.brand}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Vendors */}
                  <div className="col-span-12 lg:col-span-6">
                    <div className="">
                      <h4 className="text-gray-600 dark:text-white mb-2">
                        Vendors
                      </h4>
                      <div className=" rounded">
                        <SelectBox
                          name={"Vendors"}
                          selected={vendorSelect}
                          setSelected={setVendorSelect}
                          data={vendors}
                          errorMsg={customError.vendor}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Description */}
                  <div className="col-span-12">
                    <h4 className="text-gray-600 dark:text-white mb-2">
                      Description
                    </h4>
                    <MyRichTextEditor
                      description={description}
                      setDescription={setDescription}
                      formData={formData}
                      setFormData={setFormData}
                      errorMsg={customError.description}
                    />
                  </div>
                </div>
                <div className="mt-5 flex flx-row justify-end">
                  <ActionButton
                    bgColor={currentColor}
                    color={"#fff"}
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    borderRadius={10}
                    text={isSubmitting ? "Creating..." : "Save"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
