import axios from "axios";

export const uploadImageService = async (uri, data) => {
  const config = {
    headers: {
      "Content-Type": "video/*",
      "x-amz-acl": "public-read",
    },
  };
  const response = await axios.put(uri, data, config);
  return response;
};

export const deleteImageService = async (uri, data) => {
  const config = {
    headers: {
      "Content-Type": "video/*",
      "x-amz-acl": "public-read",
    },
  };
  const response = await axios.post(uri, data, config);
  return response;
};
