import { gql } from "@apollo/client";

export const GET_ONE_ORDER_DETAIL = gql`
  query MyQuery($id: Int!) {
    orders_by_pk(id: $id) {
      status
      shipping_address {
        id
        name
        address
        phone
        email
      }
      user {
        id
        name
        phone
      }
      totalAmount
      payment_method
      order_items {
        id
        qty
        totalPrice
        order {
          status
          updated_at
        }
        product {
          id
          name
          price
        }
        variation_color {
          id
          name
          color_code
        }
        size {
          id
          name
        }
        product_variation {
          id
          name
          price
          product_image_url
        }
      }
    }
  }
`;
