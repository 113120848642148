import { gql } from "@apollo/client";

export const GET_ONE_PRODUCT_COLLECTION = gql`
  query MyQuery($id: Int!) {
    product_collections_by_pk(id: $id) {
      collection_image_url
      description
      id
      name
      is_featured
      is_collection_gallery
      vendor {
        id
        name
        email
        phone
      }
    }
  }
`;
