import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_VARIATION = gql`
  mutation MyMutation(
    $id: Int!
    $name: String!
    $price: String!
    $product_image_url: String!
    $description: String!
    $store: Int!
    $fk_variation_color_id: Int!
  ) {
    update_product_variations_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        price: $price
        product_image_url: $product_image_url
        fk_variation_color_id: $fk_variation_color_id
        description: $description
        store: $store
      }
    ) {
      name
      id
    }
  }
`;
