import { gql } from "@apollo/client";

export const ADD_PRODUCT_COLLECTION = gql`
  mutation MyMutation(
    $fk_vendor_id: Int!
    $name: String!
    $description: String
    $collection_image_url: String!
    $is_collection_gallery: Boolean
    $is_featured: Boolean
  ) {
    insert_product_collections(
      objects: {
        collection_image_url: $collection_image_url
        name: $name
        description: $description
        fk_vendor_id: $fk_vendor_id
        is_collection_gallery: $is_collection_gallery
        is_featured: $is_featured
      }
    ) {
      returning {
        collection_image_url
        description
        fk_vendor_id
        id
        name
      }
    }
  }
`;
