import { gql } from "@apollo/client";

export const GET_ONE_COLOR = gql`
  query MyQuery($id: Int!) {
    variation_colors_by_pk(id: $id) {
      id
      name
      color_code
    }
  }
`;
