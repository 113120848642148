import { gql } from "@apollo/client";

export const GET_ALL_PRODUCTS = gql`
  query MyQuery {
    products {
      id
      fk_vendor_id
      fk_product_collection_id
      fk_product_category_id
      fk_product_activity_id
      fk_product_brand_id
      fk_body_type_id
      product_image_url
      name
      price
      status
      promotion
      sub_title
      disabled
    }
  }
`;
