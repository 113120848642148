import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_BRAND = gql`
  mutation MyMutation($id: Int!, $brand_image_url: String!, $name: String!) {
    update_product_brands_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, brand_image_url: $brand_image_url }
    ) {
      id
      name
      brand_image_url
    }
  }
`;
