import clsx from "clsx";
import React from "react";

const IconButton = ({ title, icon, color, dotColor, onClick, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{ color }}
      className={clsx(
        " text-xl dark:hover:bg-gray-700 rounded-full p-3 hover:bg-light-gray text-red-600",
        className
      )}
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  );
};

export default IconButton;
