import { gql } from "@apollo/client";

export const GET_ALL_PRODUCT_VARIATION = gql`
  query MyQuery {
    product_variations {
      description
      fk_product_id
      id
      name
      price
      size
    }
  }
`;
