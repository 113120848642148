import { gql } from "@apollo/client";

export const GET_ALL_PRODUCT_COLLECTION = gql`
  query MyQuery($fk_vendor_id: Int!) {
    product_collections(where: { fk_vendor_id: { _eq: $fk_vendor_id } }) {
      collection_image_url
      description
      id
      status
      name
      vendor {
        id
        name
        email
        phone
      }
    }
  }
`;
