import { gql } from "@apollo/client";

export const VENDOR_LOGIN = gql`
  mutation MyMutation($password: String!, $phone: String!) {
    VendorLogin(password: $password, phone: $phone) {
      accessToken
      error
      expiredAt
      message
      type
    }
  }
`;
