import { gql } from "@apollo/client";

export const CREATE_VARIATION_SIZE = gql`
  mutation MyMutation($fk_variation_id: Int!, $fk_variation_size_id: Int!) {
    insert_variation_sizes(
      objects: {
        fk_variation_id: $fk_variation_id
        fk_variation_size_id: $fk_variation_size_id
      }
    ) {
      returning {
        fk_variation_size_id
        id
      }
    }
  }
`;
