import React from "react";
import { Header } from "../components";
import { GET_ALL_VENDORS } from "../graphql/queries/getAllVendors";
import { useLazyQuery } from "@apollo/client";
const Vendors = () => {
  const [loadVendorData, getVendorData] = useLazyQuery(GET_ALL_VENDORS);
  const vendors =
    Array.isArray(getVendorData?.data?.vendors) &&
    getVendorData?.data?.vendors.length > 0
      ? getVendorData?.data?.vendors
      : [];
  React.useEffect(() => {
    loadVendorData({
      variables: {},
    });
  }, [loadVendorData]);

  return (
    <div className="p-8 dark:text-white">
      <Header title="Vendors" category={"vendors"} />
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Phone
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {vendors.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4">{item.id}</td>
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.phone}</td>
                  <td className="px-6 py-4">{item.email ? item.email : "#"}</td>
                  <td className="px-6 py-4">
                    <div className="flex flex-row">##</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Vendors;
