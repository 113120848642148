import React from "react";
import { Header } from "../components";
import { ActionButton, IconButton, TextField } from "../anh";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ONE_PRODUCT_CATEGORY } from "../graphql/queries/getOneProductCategory";
import { UPDATE_PRODUCT_CATEGORY } from "../graphql/mutations/updateProductCategory";
import * as Yup from "yup";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { IMAGE_TYPE } from "../config/common";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { uploadImageService } from "../lib/uploadImage";

const UpdateProductCategory = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const categoryId = parseInt(id);
  const formikRef = React.useRef();
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    category_image_url: "",
  });
  const [customError, setCustomError] = React.useState({
    category_image_url: "",
  });
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");

  //Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log("result", result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        category_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  //Get Detail Category
  const [loadCategoryData, getCategoryData] = useLazyQuery(
    GET_ONE_PRODUCT_CATEGORY
  );
  const category = getCategoryData?.data?.product_categories_by_pk
    ? getCategoryData.data.product_categories_by_pk
    : null;
  React.useEffect(() => {
    if (categoryId) {
      loadCategoryData({
        variables: {
          id: categoryId,
        },
      });
    }
  }, [categoryId, loadCategoryData]);

  React.useEffect(() => {
    if (category) {
      formikRef.current.setFieldValue("name", category?.name);
      setImageFile(category?.category_image_url || "");
      setImagePreview(category?.category_image_url || "");
      setFormData({
        category_image_url: category?.category_image_url ?? "",
      });
    }
  }, [category]);

  //Update Brand
  const [update_category] = useMutation(UPDATE_PRODUCT_CATEGORY, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Updated Category Successfully");
      navigate("/categories");
    },
  });

  return (
    <div className="p-6">
      <Header title="Update Category" category="Category" />
      <div className="flex flex-row justify-center">
        <div className="p-8 bg-white dark:bg-secondary-dark-bg rounded shadow w-full lg:w-5/6">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required("category name required!"),
            })}
            onSubmit={async (values) => {
              try {
                if (!imageFile && !imageFileUrl) {
                  setCustomError({
                    ...currentColor,
                    category_image_url: "category image is required",
                  });
                } else {
                  await uploadImageService(imageFileUrl, imageFile);
                  await update_category({
                    variables: {
                      id: categoryId,
                      name: values.name,
                      category_image_url: formData.category_image_url,
                    },
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="mb-4">
                  <label
                    htmlFor="upload"
                    className="cursor-pointer border-2 w-full h-[400px]  rounded border-dashed dark:border-gray-400 flex flex-col justify-center items-center relative"
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="dark:text-gray-400 text-white">
                            Click here to Upload
                          </span>
                        </div>
                        {customError.category_image_url && (
                          <h4 className="text-xl text-red-600 mt-6 capitalize">
                            {customError.category_image_url}
                          </h4>
                        )}
                      </>
                    )}
                  </label>
                </div>
                <div className="mb-4">
                  <h4 className="mb-2 dark:text-white text-xl">Name</h4>
                  <TextField
                    name="name"
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    inputSize={"medium"}
                    placeholder={"Name"}
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>
                <ActionButton
                  disabled={isSubmitting}
                  text={isSubmitting ? "Updating..." : "Save"}
                  type="submit"
                  bgColor={currentColor}
                  borderRadius={8}
                  width={"24"}
                  color={"#fff"}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductCategory;
