import { gql } from "@apollo/client";

export const ADD_PRODUCT_IMAGE = gql`
  mutation MyMutation(
    $product_image_url: String!
    $fk_variation_id: Int!
    $fk_product_id: Int!
  ) {
    insert_product_images_one(
      object: {
        product_image_url: $product_image_url
        fk_variation_id: $fk_variation_id
        fk_product_id: $fk_product_id
      }
    ) {
      id
      product_image_url
    }
  }
`;
