import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { IS_CLIENT } from "../config/common";
import { ACCESS_TOKEN } from "../config/app";

const authLink = setContext((_, { headers }) => {
  let accessToken = IS_CLIENT && window.localStorage.getItem(ACCESS_TOKEN);
  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
      "x-hasura-admin-secret": "naksportpassword2024",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (
        extensions.code === "invalid-headers" ||
        extensions.code === "invalid-jwt"
      ) {
        window.location.assign(`${window.location.origin}/login`);
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    alert("network connection problem");
  }
});

const httpLink = new HttpLink({ uri: "https://api.naksport.com/v1/graphql" });

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(authLink).concat(httpLink),
  ssrMode: typeof window === "undefined",
});
