import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./lib/apolloClient";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UIProvider } from "./contexts/UIContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <UIProvider>
        <ContextProvider>
          <App />
          <ToastContainer hideProgressBar theme="light" position="top-center" />
        </ContextProvider>
      </UIProvider>
    </ApolloProvider>
  </React.StrictMode>
);
