import React from "react";
import { Header } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_SIZES } from "../graphql/queries/getAllSizes";
import { IconButton } from "../anh";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

const VariationSizes = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [loadSizes, getSizesData] = useLazyQuery(GET_ALL_SIZES);
  React.useEffect(() => {
    loadSizes({
      variables: {},
      fetchPolicy: "network-only",
    });
  }, [loadSizes]);
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product Sizes"} category="Sizes" />
      <Link
        to={"/variation-sizes/add"}
        style={{ background: currentColor }}
        className="text-white outline-none px-3 py-2 rounded-lg"
      >
        Add Sizes
      </Link>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {Array.isArray(getSizesData?.data?.sizes) &&
                getSizesData.data.sizes.length > 0 &&
                getSizesData.data.sizes.map((size) => (
                  <tr key={size.id}>
                    <td className="px-6 py-4">{size.id}</td>
                    <td className="px-6 py-4">{size.name}</td>
                    <td className="px-6 py-4">
                      <IconButton
                        onClick={() =>
                          navigate(`/variation-sizes/edit/${size.id}`)
                        }
                        icon={<PencilSquareIcon className="w-5 h-5" />}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VariationSizes;
