import { gql } from "@apollo/client";

export const ADD_SIZE = gql`
  mutation MyMutation($name: String!) {
    insert_sizes_one(object: { name: $name }) {
      name
      id
    }
  }
`;
