import React from "react";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ONE_ORDER_DETAIL } from "../graphql/queries/getOneOrder";
import clsx from "clsx";
import { UPDATE_STATUS } from "../graphql/mutations/updateStatus";
import { toast } from "react-toastify";

const DailyReportDetail = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const orderId = parseInt(id);
  const [loadOrderDetail, getOrderDetail] = useLazyQuery(GET_ONE_ORDER_DETAIL);
  const order = getOrderDetail?.data?.orders_by_pk
    ? getOrderDetail.data.orders_by_pk
    : null;
  React.useEffect(() => {
    loadOrderDetail({
      variables: {
        id: orderId,
      },
    });
  }, [loadOrderDetail, orderId]);
  const [update_status] = useMutation(UPDATE_STATUS, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Update Status Success!");
    },
  });
  const handleUpdateStatus = (value) => {
    update_status({
      variables: {
        id: orderId,
        status: value,
      },
      fetchPolicy: "network-only",
    });
    loadOrderDetail({
      variables: {
        id: orderId,
      },
    });
  };
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Daily Report Detail"} category={"Detail Report"} />
      <Link
        to={"/daily-report"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Item
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Username
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Subtotal
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {Array.isArray(order?.order_items) &&
                order?.order_items.length > 0 &&
                order?.order_items.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4">{item.id}</td>
                    <td className="px-6 py-4 flex flex-row">
                      {item.product_variation.product_image_url && (
                        <img
                          src={item.product_variation.product_image_url}
                          alt={item.product_variation.name}
                          className="w-10 h-10 rounded-full object-cover"
                        />
                      )}
                    </td>
                    <td className="px-6 py-4">{item.product.name}</td>
                    <td className="px-6 py-4">{item.qty}</td>
                    <td className="px-6 py-4">
                      {item.product_variation.price}
                    </td>
                    <td className="px-6 py-4">{item.totalPrice}</td>
                    <td className="px-6 py-4">
                      {new Date(item?.order?.updated_at).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <span
                        className={clsx("text-white rounded px-1", {
                          "bg-red-500": order.status === "PENDING",
                          "bg-blue-500": order.status === "DELIVERY",
                          "bg-green-500": order.status === "COMPLETE",
                        })}
                      >
                        {item.order.status}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col mt-4 lg:mt-8 gap-4">
        <div>
          <h3 className="text-xl font-semibold text-gray-500">
            Shipping Address
          </h3>
          <div className="grid grid-cols-12 gap-4 mt-2 lg:mt-4">
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Name</h4>
              <p className="text-gray-400">{order?.shipping_address?.name}</p>
            </div>
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Email</h4>
              <p className="text-gray-400">{order?.shipping_address?.email}</p>
            </div>
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Phone</h4>
              <p className="text-gray-400">{order?.shipping_address?.phone}</p>
            </div>
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Payment Method</h4>
              <p className="text-gray-400">
                {order?.payment_method === "cod"
                  ? "Cash On Delivery"
                  : order?.payment_method === "cb"
                  ? "CB Pay"
                  : order?.payment_method === "mc"
                  ? "Master/Visa Cart"
                  : order?.payment_method === "kbz"
                  ? "KBZ Pay"
                  : ""}
              </p>
            </div>
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Address</h4>
              <p className="text-gray-400">
                {order?.shipping_address?.address}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-3 lg:mt-6">
          <h3 className="text-xl font-semibold text-gray-500">User Info</h3>
          <div className="grid grid-cols-12 gap-4 mt-2 lg:mt-4">
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Name</h4>
              <p className="text-gray-400">{order?.user?.name}</p>
            </div>
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Email</h4>
              <p className="text-gray-400">
                {order?.user?.email ? order?.user?.email : "#"}
              </p>
            </div>
            <div className="md:col-span-6 col-span-12 lg:col-span-4">
              <h4>Phone</h4>
              <p className="text-gray-400">{order?.user?.phone}</p>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-xl font-semibold text-gray-500">Actions</h3>
          <div className="flex flex-row items-center space-x-3 mt-2 lg:mt-4">
            {[
              { id: 1, name: "Pending", value: "PENDING" },
              { id: 2, name: "On Delivery", value: "DELIVERY" },
              { id: 3, name: "Complete", value: "COMPLETE" },
            ].map((item) => (
              <button
                onClick={() => handleUpdateStatus(item.value)}
                className={clsx(
                  "px-3 text-sm py-1 outline-none dark:text-white text-black border  rounded",
                  {
                    "bg-red-600 !text-white": order?.status === item.value,
                  }
                )}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyReportDetail;
