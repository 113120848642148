import React from "react";
import Header from "../components/Header";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { IMAGE_TYPE } from "../config/common";
import { ADD_BRAND } from "../graphql/mutations/addBrand";
import { ActionButton, IconButton, TextField } from "../anh";
import { Link, useNavigate } from "react-router-dom";
import { uploadImageService } from "../lib/uploadImage";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { GET_ALL_PRODUCT_BRANDS } from "../graphql/queries/getAllProductBrand";
import clsx from "clsx";
const CreateBrand = () => {
  const { currentColor } = useStateContext();
  const [formData, setFormData] = React.useState({
    name: "",
    brand_image_url: "",
  });
  const [customError, setCustomError] = React.useState({
    brand_image_url: "",
  });
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log("result", result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        brand_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };
  const [add_product_brand] = useMutation(ADD_BRAND, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: (result) => {
      setFormData({});
      toast.success("New Brand has been added");
      navigate("/brands");
    },
    refetchQueries: () => [
      {
        query: GET_ALL_PRODUCT_BRANDS,
      },
    ],
  });

  return (
    <div className="p-8 ">
      <Header title={"Create Brand"} category="Brand" />
      <Link
        to={"/brands"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="mt-4 flex flex-row justify-center">
        <div className="p-8 bg-white rounded dark:bg-secondary-dark-bg w-full lg:w-5/6 shadow">
          <Formik
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required("brand name required!"),
            })}
            onSubmit={async (values) => {
              try {
                if (!imageFile && !imageFileUrl) {
                  setCustomError({
                    ...currentColor,
                    brand_image_url: "brand image is required",
                  });
                } else {
                  await uploadImageService(imageFileUrl, imageFile);

                  await add_product_brand({
                    variables: {
                      brand_image_url: formData.brand_image_url,
                      name: values.name,
                    },
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="col-span-12">
                  <label
                    htmlFor="upload"
                    className={clsx(
                      "cursor-pointer border-2 w-full h-[400px] rounded border-dashed relative  flex flex-col justify-center items-center",
                      {
                        "border-red-600": customError.image,
                        "border-gray-400": !customError.image,
                      }
                    )}
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-contain"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="text-gray-400">
                            Click here to Upload
                          </span>
                        </div>

                        {customError.brand_image_url && (
                          <h4 className="text-xl text-red-600 mt-6 capitalize">
                            {customError.brand_image_url}
                          </h4>
                        )}
                      </>
                    )}
                  </label>
                  <p className="text-center text-gray-400">
                    Width 210px and Height 275px
                  </p>
                </div>
                <div className="mb-3">
                  <h4 className="mb-2 dark:text-white text-back">Name</h4>
                  <TextField
                    name="name"
                    id={"name"}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Name"
                    type="text"
                    inputSize="medium"
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>
                <div className="">
                  <ActionButton
                    disabled={isSubmitting}
                    type="submit"
                    text={isSubmitting ? "Creating.." : "Save"}
                    bgColor={currentColor}
                    width={"full"}
                    color={"#fff"}
                    borderRadius={8}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateBrand;
