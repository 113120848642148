import React from "react";
const initialState = {
  dialog: {
    name: "",
    data: null,
  },
};

export const UIContext = React.createContext(initialState);

export const uiReducer = (state, action) => {
  switch (action.type) {
    case "SET_DIALOG": {
      return {
        ...state,
        dialog: {
          name: action.payload.name,
          data: action.payload.data,
        },
      };
    }

    default:
      return state;
  }
};

export const UIProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);
  const setDialog = React.useCallback(
    (payload) => dispatch({ type: "SET_DIALOG", payload }),
    [dispatch]
  );
  const value = React.useMemo(
    () => ({
      ...state,
      setDialog,
    }),
    [state, setDialog]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUI = () => {
  const context = React.useContext(UIContext);

  if (context === undefined)
    throw new Error(`useUI must be used within a UIProvider`);

  return context;
};
