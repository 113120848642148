import React from "react";
import { Header } from "../components";
import { ActionButton, SelectBox, TextField } from "../anh";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { GET_ALL_PRODUCT_CATEGORIES } from "../graphql/queries/getAllProductCategories";
import { GET_SUBCATEGORY_BY_CATID } from "../graphql/queries/getSubCategoryByCatId";
import { GET_ONE_PRODUCT_MODEL } from "../graphql/queries/getOneProductModel";
import { UPDATE_PRODUCT_MODEL } from "../graphql/mutations/updateProductModel";

const UpdateProductModel = () => {
  const { currentColor } = useStateContext();
  const formikRef = React.useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const modelId = parseInt(id);

  const [categorySelect, setCategorySelect] = React.useState();
  const [subcategorySelect, setSubCategorySelect] = React.useState();
  //Get Product model
  const [loadProductModel, getProdutModel] = useLazyQuery(
    GET_ONE_PRODUCT_MODEL
  );
  const product_model = getProdutModel?.data?.product_types_by_pk
    ? getProdutModel.data.product_types_by_pk
    : null;
  React.useEffect(() => {
    loadProductModel({
      variables: {
        id: modelId,
      },
    });
  }, [loadProductModel, modelId]);
  //Get All Categories
  const [loadCategories, getCategories] = useLazyQuery(
    GET_ALL_PRODUCT_CATEGORIES
  );
  const categories =
    Array.isArray(getCategories?.data?.product_categories) &&
    getCategories?.data?.product_categories.length > 0
      ? getCategories?.data?.product_categories
      : [];
  React.useEffect(() => {
    loadCategories({
      variables: {},
    });
  }, [loadCategories]);

  //   Get All Subcategory
  const [loadSubCategories, getSubCategories] = useLazyQuery(
    GET_SUBCATEGORY_BY_CATID
  );
  const subcategories =
    Array.isArray(getSubCategories?.data?.product_subcategories) &&
    getSubCategories?.data?.product_subcategories.length > 0
      ? getSubCategories?.data?.product_subcategories
      : [];
  React.useEffect(() => {
    loadSubCategories({
      variables: {
        fk_category_id: categorySelect?.id
          ? categorySelect?.id
          : product_model?.product_subcategory?.product_category?.id,
      },
    });
  }, [
    categorySelect?.id,
    loadSubCategories,
    product_model?.product_subcategory?.product_category?.id,
  ]);
  //Update Brand
  const [update_product_model] = useMutation(UPDATE_PRODUCT_MODEL, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Updated Product Model Successfully");
      navigate("/product-models");
    },
    refetchQueries: () => [
      {
        query: GET_ALL_PRODUCT_CATEGORIES,
      },
    ],
  });
  //Show Update value in input
  React.useEffect(() => {
    formikRef.current.setFieldValue("name", product_model?.name || "");
  }, [product_model]);
  return (
    <div className="p-6">
      <Header title="Update Product Model" category="Product Model" />
      <Link
        to={"/product-models"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="flex flex-row justify-center">
        <div className="p-8 bg-white dark:bg-secondary-dark-bg rounded shadow w-full lg:w-5/6">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(255)
                .required("Product Model name required!"),
            })}
            onSubmit={async (values) => {
              try {
                await update_product_model({
                  variables: {
                    id: modelId,
                    name: values.name,
                    fk_subcategory_id: subcategorySelect?.id
                      ? subcategorySelect?.id
                      : product_model?.product_subcategory?.id,
                  },
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              values,
            }) => (
              <Form innerRef={formikRef} onSubmit={handleSubmit} noValidate>
                <div className="mb-4">
                  <h4 className="text-gray-600 dark:text-white mb-2">
                    Category
                  </h4>
                  <SelectBox
                    data={categories}
                    selected={
                      !categorySelect
                        ? categories.find(
                            (item) =>
                              item.id ===
                              product_model?.product_subcategory
                                ?.product_category?.id
                          )
                        : categorySelect
                    }
                    setSelected={setCategorySelect}
                    name={"category"}
                  />
                </div>
                <div className="mb-4">
                  <h4 className="text-gray-600 dark:text-white mb-2">
                    Sub Category{" "}
                    <span className="text-gray-400 text-sm">
                      (Please first choose category)
                    </span>
                  </h4>
                  <SelectBox
                    data={subcategories}
                    selected={
                      !subcategorySelect
                        ? subcategories.find(
                            (item) =>
                              item.id === product_model?.product_subcategory?.id
                          )
                        : subcategorySelect
                    }
                    setSelected={setSubCategorySelect}
                    name={"Sub Category"}
                  />
                </div>
                <div className="mb-4">
                  <h4 className="mb-2 dark:text-white text-xl">
                    Product Model
                  </h4>
                  <TextField
                    disabled={isSubmitting}
                    name="name"
                    id="name"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    inputSize={"medium"}
                    placeholder={"Name"}
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>
                <ActionButton
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  text={isSubmitting ? "Creating.." : "Save"}
                  bgColor={currentColor}
                  borderRadius={8}
                  width={"24"}
                  color={"#fff"}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductModel;
