import { gql } from "@apollo/client";

export const ADD_AUTO_VARIATION = gql`
  mutation MyMutation(
    $name: String!
    $fk_product_id: Int!
    $fk_variation_color_id: Int
    $price: String!
    $description: String!
    $product_image_url: String!
    $store: Int!
  ) {
    insert_product_variations_one(
      object: {
        name: $name
        fk_product_id: $fk_product_id
        price: $price
        description: $description
        product_image_url: $product_image_url
        fk_variation_color_id: $fk_variation_color_id
        store: $store
      }
    ) {
      name
      fk_product_id
      description
      id
      price
      product_image_url
    }
  }
`;
