import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_PRODUCTS } from "../graphql/queries/getAllProduct";
import clsx from "clsx";
import { IconButton } from "../anh";

const Products = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [loadProduct, getProductData] = useLazyQuery(GET_ALL_PRODUCTS);
  const products =
    Array.isArray(getProductData?.data?.products) &&
    getProductData?.data?.products.length > 0
      ? getProductData?.data.products
      : [];
  //Get All Products
  React.useEffect(() => {
    loadProduct({
      variables: {},
      fetchPolicy: "network-only",
    });
  }, [loadProduct]);

  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product"} category={"Product"} />
      <Link
        to={"/products/add"}
        style={{ background: currentColor }}
        className="text-white outline-none px-3 py-2 rounded-lg"
      >
        Add Product
      </Link>

      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Product Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {products.map((item) => (
                <tr key={item.id} className="">
                  <td className="px-6 py-4">{item.id}</td>
                  <td className="px-6 py-4">
                    <div className="relative h-14 w-14">
                      <img
                        src={item.product_image_url}
                        alt={item.name}
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.product_type}</td>
                  <td className="px-6 py-4">{item.price}</td>
                  <td className="px-6 py-4">
                    <span
                      className={clsx("text-white px-2 rounded", {
                        "bg-green-400": item.status,
                        "bg-red-400": !item.status,
                      })}
                    >
                      {" "}
                      {item.status ? "Active" : "Pending"}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-row items-center">
                      <Link
                        to={`/products/${item.id}`}
                        className="px-3 py-2 text-white mr-2 rounded"
                        style={{ background: currentColor }}
                      >
                        Detail
                      </Link>

                      <IconButton
                        onClick={() => navigate(`/products/edit/${item.id}`)}
                        icon={
                          <PencilSquareIcon
                            style={{ color: currentColor }}
                            className="w-6  h-6"
                          />
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Products;
