import { gql } from "@apollo/client";

export const GET_ONE_BLOG = gql`
  query MyQuery($id: Int!) {
    blogs_by_pk(id: $id) {
      disabled
      description
      blog_image_url
      id
      name
      status
    }
  }
`;
