import clsx from "clsx";
import React from "react";

const ActionButton = ({
  icon,
  bgColor,
  color,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width,
  onClick,
  type = "button",
  disabled,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={clsx(
        `text-${size} flex flex-row items-center justify-center p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`,
        {
          "!bg-gray-600 bg-opacity-40 text-gray-400 border-transparent  pointer-events-none":
            disabled,
        }
      )}
    >
      {icon} {text}
    </button>
  );
};

export default ActionButton;
