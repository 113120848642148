import React from "react";
import Header from "../components/Header";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { IMAGE_TYPE } from "../config/common";
import { ActionButton, IconButton, TextField } from "../anh";
import { useNavigate, useParams } from "react-router-dom";
import { uploadImageService } from "../lib/uploadImage";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GET_ONE_PRODUCT_COLLECTION } from "../graphql/queries/getOneProductCollection";
import { UPDATE_PRODUCT_COLLECTION } from "../graphql/mutations/updateProductCollection";
import { MyRichTextEditor } from "../components";
import RichTextEditor from "react-rte";
import clsx from "clsx";
const UpdateProductCollection = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const collectionId = parseInt(id);
  const [formData, setFormData] = React.useState({
    collection_image_url: "",
    description: "",
  });
  const [check, setChecked] = React.useState({
    gallery: false,
    featured: false,
  });
  const [customError, setCustomError] = React.useState({
    description: "",
    image: "",
    status: "",
  });
  const [description, setDescription] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");

  const formikRef = React.useRef(null);
  //Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log("result", result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        collection_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  //Get Product Collection Detail
  const [loadProductCollection, getProductCollection] = useLazyQuery(
    GET_ONE_PRODUCT_COLLECTION
  );
  const collection = getProductCollection?.data?.product_collections_by_pk
    ? getProductCollection.data.product_collections_by_pk
    : null;
  console.log(collection);
  React.useEffect(() => {
    loadProductCollection({
      variables: {
        id: collectionId,
      },
    });
  }, [collectionId, loadProductCollection]);

  //Show Value In InputBox
  React.useEffect(() => {
    if (collection) {
      formikRef.current.setFieldValue("name", collection?.name);
      setImageFile(collection?.collection_image_url ?? "");
      setImagePreview(collection?.collection_image_url ?? "");
      setFormData({
        description: collection?.description,
        collection_image_url: collection?.collection_image_url ?? "",
      });
      setChecked({
        featured: collection?.is_featured,
        gallery: collection?.is_collection_gallery,
      });
      setDescription(
        RichTextEditor.createValueFromString(collection?.description, "html")
      );
    }
  }, [collection]);
  const [update_product_collection] = useMutation(UPDATE_PRODUCT_COLLECTION, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: (result) => {
      setFormData({});
      toast.success("New Collection has been added");
      navigate("/collections");
    },
  });
  const handleChangeGallery = (event) => {
    setChecked({ ...check, gallery: event.target.checked });
  };
  const handleChangeFeatured = (event) => {
    setChecked({ ...check, featured: event.target.checked });
  };
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Update Product Collection"} category="Collecton" />
      <div className="mt-4 flex flex-row justify-center">
        <div className="p-8 bg-white rounded dark:bg-secondary-dark-bg w-full lg:w-5/6 shadow">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required("name required!"),
            })}
            onSubmit={async (values) => {
              try {
                if (!imageFile && !imageFileUrl) {
                  setCustomError({
                    ...currentColor,
                    image: "Collection image is required",
                  });
                } else if (!check.featured && !check.gallery) {
                  setCustomError({
                    ...currentColor,
                    status: "Please Chose One",
                  });
                } else if (!formData?.description) {
                  setCustomError({
                    ...currentColor,
                    description: "description is required",
                  });
                } else {
                  await uploadImageService(imageFileUrl, imageFile);
                  await update_product_collection({
                    variables: {
                      id: collection?.id,
                      collection_image_url: formData.collection_image_url,
                      name: values.name,
                      description: formData.description,
                    },
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="mb-4">
                  <label
                    htmlFor="upload"
                    className={clsx(
                      "cursor-pointer border-2 w-full h-[400px] rounded border-dashed relative  flex flex-col justify-center items-center",
                      {
                        "border-red-600": customError.image,
                        "border-gray-400": !customError.image,
                      }
                    )}
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="text-gray-400">
                            Click here to Upload
                          </span>
                        </div>

                        {customError.image && (
                          <h4 className="text-xl text-red-600 mt-6 capitalize">
                            {customError.image}
                          </h4>
                        )}
                      </>
                    )}
                  </label>
                  <p className="text-center text-gray-400">
                    Width 260px and Height 320px
                  </p>
                </div>
                <div className="mb-3">
                  <h4 className="mb-2">Name</h4>
                  <TextField
                    name="name"
                    id={"name"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Name"
                    type="text"
                    value={values.name}
                    inputSize="medium"
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>
                <div className="mb-4">
                  <div className="flex flex-row space-x-4">
                    <label
                      htmlFor="gallery"
                      className="flex flex-row space-x-2 items-center cursor-pointer"
                    >
                      <input
                        onChange={handleChangeGallery}
                        type="checkbox"
                        id="gallery"
                        checked={check.gallery}
                      />
                      <span>Gallery</span>
                    </label>
                    <label
                      htmlFor="featured"
                      className="flex flex-row space-x-2 items-center cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        id="featured"
                        onChange={handleChangeFeatured}
                        checked={check.featured}
                      />
                      <span>Featured</span>
                    </label>
                  </div>
                  {!check.featured && !check.gallery && (
                    <span className="text-red-600 text-sm">
                      {customError.status}
                    </span>
                  )}
                </div>
                {/* Description */}
                <div className="mb-4">
                  <h4 className="text-gray-600 dark:text-white mb-2">
                    Description
                  </h4>
                  <MyRichTextEditor
                    description={description}
                    setDescription={setDescription}
                    formData={formData}
                    setFormData={setFormData}
                    errorMsg={customError.description}
                  />
                </div>
                <div className="">
                  <ActionButton
                    disabled={isSubmitting}
                    type="submit"
                    text={isSubmitting ? "Creating.." : "Save"}
                    bgColor={currentColor}
                    width={"full"}
                    borderRadius={8}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductCollection;
