import { gql } from "@apollo/client";

export const UPDATE_SUBCATEGORY_BY_ID = gql`
  mutation MyMutation($id: Int!, $fk_category_id: Int!, $name: String!) {
    update_product_subcategories_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, fk_category_id: $fk_category_id }
    ) {
      id
      name
      status
      fk_category_id
    }
  }
`;
