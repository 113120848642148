import React from "react";
import Header from "../components/Header";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IMAGE_TYPE } from "../config/common";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { uploadImageService } from "../lib/uploadImage";
import TextField from "../anh/TextField";
import { GET_PRODUCT_VARIATION_BY_ID } from "../graphql/queries/getProductVariationById";
import { UPDATE_PRODUCT_VARIATION } from "../graphql/mutations/updateProductVariation";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import { MyRichTextEditor } from "../components";
import { ActionButton, MutipleSelectBox } from "../anh";
import { GET_VARIATION_SIZE } from "../graphql/queries/getVariationSize";
import { GET_ALL_COLORS } from "../graphql/queries/getAllColors";
import clsx from "clsx";

const UpdateProductVariation = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const variationId = parseInt(id);

  const navigate = useNavigate();
  const formikRef = React.useRef();

  const [formData, setFormData] = React.useState({
    product_image_url: "",
    description: "",
  });
  const [productSizeSelect, setProductSizeSelect] = React.useState([]);
  const [description, setDescription] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const [chooseColor, setChooseColor] = React.useState();
  const [colorToggle, setColorToggle] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");

  //Get Production Variation By Id
  const [loadProductVariation, getProductVariation] = useLazyQuery(
    GET_PRODUCT_VARIATION_BY_ID
  );
  const variation = getProductVariation?.data?.product_variations_by_pk
    ? getProductVariation.data.product_variations_by_pk
    : null;
  const sizes =
    Array.isArray(getProductVariation?.data?.sizes) &&
    getProductVariation?.data?.sizes.length > 0
      ? getProductVariation?.data?.sizes
      : [];
  const color = getProductVariation?.data?.product_variations_by_pk
    ?.variation_color
    ? getProductVariation.data.product_variations_by_pk.variation_color
    : null;
  console.log(color);
  React.useEffect(() => {
    setChooseColor(color);
  }, [color]);
  React.useEffect(() => {
    loadProductVariation({
      variables: {
        id: variationId,
      },
    });
  }, [variationId, loadProductVariation]);

  //Handler Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log(result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        product_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };
  //Show Values In Input Box
  React.useEffect(() => {
    if (variation) {
      setImageFile(variation?.product_image_url ?? "");
      setImagePreview(variation?.product_image_url);
      formikRef.current.setFieldValue("name", variation?.name);
      formikRef.current.setFieldValue("price", variation?.price);
      formikRef.current.setFieldValue("store", variation?.store);
      setFormData({
        description: variation?.description ?? "",
        product_image_url: variation?.product_image_url ?? "",
      });
      setDescription(
        RichTextEditor.createValueFromString(variation?.description, "html")
      );
    }
  }, [variation]);

  // Get Variation Size By variation id
  const [loadVariationSize, getVariationSize] =
    useLazyQuery(GET_VARIATION_SIZE);
  const choose_variation_size =
    Array.isArray(getVariationSize?.data?.variation_sizes) &&
    getVariationSize?.data?.variation_sizes.length > 0
      ? getVariationSize?.data.variation_sizes.map((item) => ({ ...item.size }))
      : [];
  React.useEffect(() => {
    loadVariationSize({
      variables: {
        fk_variation_id: variationId,
      },
    });
  }, [loadVariationSize, variationId]);

  //Fetch All Colors
  const [loadColorsData, getColorsData] = useLazyQuery(GET_ALL_COLORS);
  const colorData =
    Array.isArray(getColorsData?.data?.variation_colors) &&
    getColorsData?.data?.variation_colors.length > 0
      ? getColorsData?.data?.variation_colors
      : [];

  React.useEffect(() => {
    loadColorsData({
      variables: {},
    });
  }, [loadColorsData]);

  //Update Product Variation
  const [update_product_variation] = useMutation(UPDATE_PRODUCT_VARIATION, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Updated Variation Successfully!");
      navigate(-1);
    },
  });
  return (
    <div className=" p-8 rounded ">
      <Header title={"Update Product Variation"} category="Product Variation" />
      <ActionButton
        color={"#fff"}
        borderRadius={8}
        bgColor={currentColor}
        text={"Back"}
        onClick={() => navigate(-1)}
      />
      <div className="p-8 mt-4 bg-white rounded dark:bg-secondary-dark-bg shadow">
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            name: "",
            price: "",
            store: "",
          }}
          onSubmit={async (values) => {
            try {
              if (imageFile && imageFileUrl) {
                await uploadImageService(imageFileUrl, imageFile);
              }
              await update_product_variation({
                variables: {
                  id: variationId,
                  name: values.name,
                  price: values.price,
                  fk_variation_color_id: chooseColor?.id,
                  description: formData.description,
                  product_image_url: formData.product_image_url,
                  store: parseInt(values.store),
                },
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <div>
                  <label
                    htmlFor="upload_variation_id"
                    className="h-[400px]  flex flex-col justify-center items-center cursor-pointer border-2 border-dashed rounded"
                  >
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        className="h-full w-full object-cover"
                        alt="Brand_Image"
                      />
                    ) : (
                      <>
                        <input
                          id="upload_variation_id"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />
                          <span className="dark:text-gray-400 text-white">
                            Click here to Upload
                          </span>
                        </div>
                      </>
                    )}
                  </label>
                </div>
                <div>
                  <h4 className="text-black dark:text-white">Name</h4>
                  <TextField
                    value={values.name}
                    disabled={isSubmitting}
                    fullWidth
                    onChange={handleChange}
                    placeholder={"Name"}
                    inputSize={"medium"}
                    id={"name"}
                    name="name"
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  <h4 className="text-black dark:text-white">Price</h4>
                  <TextField
                    fullWidth
                    value={values.price}
                    onChange={handleChange}
                    placeholder={"Price"}
                    inputSize={"medium"}
                    id={"price"}
                    name="price"
                    onBlur={handleBlur}
                  />
                </div>
                <div className=" rounded">
                  <MutipleSelectBox
                    isEdit={true}
                    variationId={variationId}
                    name={"Varation Sizes"}
                    selected={
                      productSizeSelect.length === 0
                        ? choose_variation_size
                        : productSizeSelect
                    }
                    setSelected={setProductSizeSelect}
                    data={sizes}
                  />
                </div>
                <div className="relative">
                  <h4 className="dark:text-white text-black ">
                    Variation Colors{" "}
                  </h4>
                  <div className="border p-3 rounded  text-black dark:text-white">
                    {chooseColor ? (
                      <span className="uppercase">
                        {chooseColor?.color_code}
                      </span>
                    ) : (
                      <span className="text-gray-400">Colors</span>
                    )}
                  </div>
                  <div
                    onClick={() => setColorToggle((prev) => !prev)}
                    className="cursor-pointer p-2 rounded-full hover:bg-black disabled:hover:bg-inherit disabled:cursor-not-allowed disabled:text-gray-500 dark:disabled:text-gray-800 hover:bg-opacity-10 ring-red-500 focus:ring-4 absolute top-7 inline-block right-0"
                  >
                    {colorToggle ? (
                      <ChevronUpIcon className="w-6 h-6 text-black dark:text-white  cursor-pointer" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6 text-black dark:text-white  cursor-pointer" />
                    )}
                  </div>
                  {colorToggle && (
                    <div className="flex flex-col p-2 z-40 bg-main-dark-bg rounded absolute top-20 w-full h-[300px] overflow-y-auto">
                      <div className="grid grid-cols-12 gap-2">
                        {Array.isArray(colorData) &&
                          colorData.length > 0 &&
                          colorData.map((color) => (
                            <div
                              onClick={() => setChooseColor(color)}
                              key={color.id}
                              className={clsx(
                                "col-span-2 p-8 rounded  cursor-pointer",
                                {
                                  "border-2 border-red-500":
                                    chooseColor?.id === color.id,
                                }
                              )}
                              style={{ background: color.color_code }}
                            >
                              <p className="text-white">{color.color_code}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h4 className="text-black dark:text-white">Store</h4>
                  <TextField
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={isSubmitting}
                    placeholder="Store"
                    name="store"
                    id="store"
                    value={values.store}
                    inputSize={"medium"}
                    fullWidth
                  />
                </div>
                <div className="w-full">
                  <h4 className="text-black dark:text-white">Description</h4>
                  <MyRichTextEditor
                    description={description}
                    setDescription={setDescription}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
                <div className="flex flex-row justify-end mt-6">
                  <button
                    disabled={isSubmitting}
                    style={{ background: currentColor }}
                    className="px-4 disabled:bg-gray-400 disabled:cursor-not-allowed py-2 rounded text-white"
                  >
                    {isSubmitting ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateProductVariation;
