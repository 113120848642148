import React from "react";
import Header from "../components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { Form, Formik } from "formik";
import { ActionButton, TextField } from "../anh";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ONE_SIZE } from "../graphql/queries/getOneSize";
import * as Yup from "yup";
import { UPDATE_SIZE } from "../graphql/mutations/updateSize";
import { toast } from "react-toastify";
const UpdateVariationSize = () => {
  const { currentColor } = useStateContext();
  const formikRef = React.useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const sizeId = parseInt(id);
  const [loadSize, getSizeData] = useLazyQuery(GET_ONE_SIZE);
  const size = getSizeData?.data?.sizes_by_pk
    ? getSizeData?.data?.sizes_by_pk
    : null;
  React.useEffect(() => {
    loadSize({
      variables: {
        id: sizeId,
      },
    });
  }, [loadSize, sizeId]);

  //Show Values
  React.useEffect(() => {
    formikRef.current.setFieldValue("name", size?.name);
  }, [size?.name]);

  const [update_size] = useMutation(UPDATE_SIZE, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Added Size Success!");
      navigate("/variation-sizes");
    },
  });
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Update Sizes"} category="Sizess" />
      <Link
        to={"/variation-colors"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="mt-4 flex flex-row justify-center">
        <div className="p-8 bg-white rounded dark:bg-secondary-dark-bg w-full lg:w-5/6 shadow">
          <Formik
            innerRef={formikRef}
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required("Name required!"),
            })}
            onSubmit={async (values) => {
              try {
                await update_size({
                  variables: {
                    id: sizeId,
                    name: values.name,
                  },
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="mb-3">
                  <h4 className="mb-2 dark:text-white text-back">Name</h4>
                  <TextField
                    name="name"
                    id={"name"}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="Name"
                    type="text"
                    inputSize="medium"
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>

                <div className="">
                  <ActionButton
                    disabled={isSubmitting}
                    type="submit"
                    text={isSubmitting ? "Creating.." : "Save"}
                    bgColor={currentColor}
                    width={"full"}
                    color={"#fff"}
                    borderRadius={8}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariationSize;
