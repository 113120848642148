import React from "react";
import Header from "../components/Header";
import { IconButton } from "../anh";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ALL_PRODUCT_BRANDS } from "../graphql/queries/getAllProductBrand";
import clsx from "clsx";
import { DELETE_PRODUCT_BRAND } from "../graphql/mutations/deleteProductBrand";
import { toast } from "react-toastify";

const Brands = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [loadProductBrand, getProductBrandData] = useLazyQuery(
    GET_ALL_PRODUCT_BRANDS
  );
  React.useEffect(() => {
    loadProductBrand({
      variables: {},
      fetchPolicy: "network-only",
    });
  }, [loadProductBrand]);

  //Delete Product Brand
  const [delete_product_brand] = useMutation(DELETE_PRODUCT_BRAND, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (result) => {
      toast.success("Delete Brand Successfully");
      navigate("/brands");
    },
  });
  const handleDeleteBrand = async (deleteId) => {
    await delete_product_brand({
      variables: {
        id: deleteId,
      },
    });
  };
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Product Brand"} category="Brand" />
      <Link
        to={"/brands/add"}
        style={{ background: currentColor }}
        className="text-white outline-none px-3 py-2 rounded-lg"
      >
        Add Brand
      </Link>
      <div className="mt-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow dark:border-none">
          <table className="w-full border-collapse bg-white dark:bg-secondary-dark-bg text-left text-sm text-gray-500">
            <thead className="bg-gray-50 dark:bg-secondary-dark-bg">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-500 border-t border-gray-100 dark:border-gray-500">
              {Array.isArray(getProductBrandData?.data?.product_brands) &&
                getProductBrandData?.data.product_brands.length > 0 &&
                getProductBrandData?.data.product_brands.map((data) => (
                  <tr key={data.id}>
                    <td className="px-6 py-4">{data.id}</td>
                    <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                      <div className="relative h-14 w-14">
                        <img
                          src={data.brand_image_url}
                          alt={data.name}
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                    </th>

                    <td className="px-6 py-4">{data.name}</td>
                    <td className="px-6 py-4">
                      <span
                        className={clsx("text-white px-2 rounded", {
                          "bg-green-400": data.status,
                          "bg-red-400": !data.status,
                        })}
                      >
                        {" "}
                        {data.status ? "Active" : "Pending"}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-row">
                        <IconButton
                          onClick={() => handleDeleteBrand(data.id)}
                          icon={<TrashIcon className="w-6 h-6" />}
                        />
                        <IconButton
                          onClick={() => navigate(`/brands/edit/${data.id}`)}
                          icon={
                            <PencilSquareIcon
                              style={{ color: currentColor }}
                              className="w-6 h-6"
                            />
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Brands;
