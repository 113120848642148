import { gql } from "@apollo/client";

export const ADD_PRODUCT = gql`
  mutation MyMutation(
    $name: String!
    $description: String!
    $fk_product_subcategory_id: Int!
    $fk_product_collection_id: Int!
    $fk_product_type_id: Int!
    $sub_title: String!
    $fk_body_type_id: Int!
    $fk_vendor_id: Int!
    $fk_product_brand_id: Int!
    $fk_product_activity_id: Int!
    $fk_product_category_id: Int!
    $product_image_url: String!
    $price: String!
  ) {
    insert_products_one(
      object: {
        name: $name
        description: $description
        sub_title: $sub_title
        fk_product_collection_id: $fk_product_collection_id
        fk_body_type_id: $fk_body_type_id
        fk_product_type_id: $fk_product_type_id
        fk_vendor_id: $fk_vendor_id
        fk_product_brand_id: $fk_product_brand_id
        fk_product_activity_id: $fk_product_activity_id
        fk_product_category_id: $fk_product_category_id
        fk_product_subcategory_id: $fk_product_subcategory_id
        product_image_url: $product_image_url
        price: $price
        status: true
      }
    ) {
      fk_product_activity_id
      fk_vendor_id
      name
      sub_title
      fk_body_type_id
      fk_product_brand_id
      fk_product_activity_id
      fk_product_category_id
      fk_product_type_id
      product_image_url
      price
      description
      id
    }
  }
`;
