import React from "react";
import Header from "../components/Header";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { ActionButton, IconButton, MutipleSelectBox, TextField } from "../anh";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_PRODUCT_VARIATION } from "../graphql/mutations/addProductVariation";
import { Form, Formik } from "formik";
import { IMAGE_TYPE } from "../config/common";
import { toast } from "react-toastify";
import { IMAGE_UPLOAD } from "../graphql/mutations/getImageUploadUrl";
import { GET_ALL_PRODUCT_VARIATION } from "../graphql/queries/getAllVariations";
import { uploadImageService } from "../lib/uploadImage";
import { MyRichTextEditor } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import { GET_ALL_SIZES } from "../graphql/queries/getAllSizes";
import { CREATE_VARIATION_SIZE } from "../graphql/mutations/addVariatonSize";
import clsx from "clsx";
import { GET_ALL_COLORS } from "../graphql/queries/getAllColors";
import { ADD_PRODUCT_IMAGE } from "../graphql/mutations/addProductImage";

const CreateProductVariation = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const productId = parseInt(id);
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    product_image_url: "",
    description: "",
  });
  const [customError, setCustomError] = React.useState({
    image: "",
  });
  const [description, setDescription] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const [chooseColor, setChooseColor] = React.useState();
  const [imagePreview, setImagePreview] = React.useState("");
  const [imageFile, setImageFile] = React.useState("");
  const [imageFileUrl, setImageFileUrl] = React.useState("");
  const [variationSizes, setVariationSize] = React.useState([]);
  const [colorToggle, setColorToggle] = React.useState(false);
  //Fetch Sizes
  const [loadSizeData, getSizeData] = useLazyQuery(GET_ALL_SIZES);
  const sizes =
    Array.isArray(getSizeData?.data?.sizes) &&
    getSizeData?.data?.sizes.length > 0
      ? getSizeData?.data?.sizes
      : [];
  React.useEffect(() => {
    loadSizeData({
      variables: {},
    });
  }, [loadSizeData]);
  //Fetch All Colors
  const [loadColorsData, getColorsData] = useLazyQuery(GET_ALL_COLORS);
  const colorData =
    Array.isArray(getColorsData?.data?.variation_colors) &&
    getColorsData?.data?.variation_colors.length > 0
      ? getColorsData?.data?.variation_colors
      : [];

  React.useEffect(() => {
    loadColorsData({
      variables: {},
    });
  }, [loadColorsData]);
  //Handler Upload Image
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      console.log("Error : ", error.message);
    },
    onCompleted: (result) => {
      console.log(result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setFormData({
        ...formData,
        product_image_url: `https://axra.sgp1.digitaloceanspaces.com/NAKSport/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const chooseImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!IMAGE_TYPE.includes(image.type)) {
        toast.error("Please Select image (png,jpeg)");
        return;
      }
      if (image.size > 10485760) {
        toast.error("Image size must be smaller than 10MB");
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  //Add Varation Size
  const [add_variation_sizes] = useMutation(CREATE_VARIATION_SIZE, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: (result) => {
      console.log("Create Variation Size Successfully!");
    },
  });

  //Add Auto Image
  const [add_images] = useMutation(ADD_PRODUCT_IMAGE, {
    onError: (error) => {},
    onCompleted: (result) => {},
  });
  //Add Product Variation
  const [add_Product_Variation] = useMutation(ADD_PRODUCT_VARIATION, {
    onError: (err) => {
      console.log("Error : ", err.message);
    },
    onCompleted: ({ insert_product_variations_one }) => {
      if (variationSizes.length > 0) {
        add_images({
          variables: {
            fk_variation_id: insert_product_variations_one?.id,
            fk_product_id: productId,
            product_image_url: insert_product_variations_one?.product_image_url,
          },
        });
        variationSizes.map((item) =>
          add_variation_sizes({
            variables: {
              fk_variation_id: insert_product_variations_one?.id,
              fk_variation_size_id: item?.id,
            },
          })
        );
      }
      toast.success("New Variation has been added");
      navigate(-1);
    },
    refetchQueries: () => [
      {
        query: GET_ALL_PRODUCT_VARIATION,
      },
    ],
  });

  return (
    <div className="p-8 rounded ">
      <Header title="Create Product Variation" category="Product Variation" />
      <ActionButton
        color={"#fff"}
        borderRadius={8}
        bgColor={currentColor}
        text={"Back"}
        onClick={() => navigate(-1)}
      />
      <div className="p-8 mt-4 bg-white rounded dark:bg-secondary-dark-bg shadow">
        <Formik
          initialValues={{
            name: "",
            price: "",
            store: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("product name required!"),
            price: Yup.string().max(255).required("product type required!"),
            store: Yup.string().max(255).required("price required!"),
          })}
          onSubmit={async (values) => {
            try {
              if (!imageFile && !imageFileUrl) {
                setCustomError({
                  image: "Image is required",
                });
              } else {
                await uploadImageService(imageFileUrl, imageFile);
                await add_Product_Variation({
                  variables: {
                    name: values.name,
                    price: values.price,
                    description: formData.description,
                    product_image_url: formData.product_image_url,
                    fk_product_id: productId,
                    fk_variation_color_id: chooseColor?.id,
                    store: parseInt(values.store),
                  },
                });
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <div>
                  <label
                    htmlFor="upload"
                    className={clsx(
                      "cursor-pointer border-2 w-full h-[400px] rounded border-dashed relative  flex flex-col justify-center items-center",
                      {
                        "border-red-600": customError.image,
                        "border-gray-400": !customError.image,
                      }
                    )}
                  >
                    {imagePreview ? (
                      <>
                        <img
                          src={imagePreview}
                          className="h-full w-full object-cover"
                          alt="Brand_Image"
                        />
                        <IconButton
                          onClick={() => setImagePreview()}
                          className={"absolute top-0 right-0"}
                          icon={<XMarkIcon className="w-5 h-5" />}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="upload"
                          type="file"
                          onChange={chooseImage}
                          hidden
                        />
                        <div className="flex flex-row items-center">
                          <CloudArrowUpIcon
                            style={{ color: currentColor }}
                            className="mr-2 w-8 h-8"
                          />{" "}
                          <span className="text-gray-400">
                            Click here to Upload
                          </span>
                        </div>

                        {customError.image && (
                          <h4 className="text-xl text-red-600 mt-6 capitalize">
                            {customError.image}
                          </h4>
                        )}
                      </>
                    )}
                  </label>
                </div>
                <div>
                  <h4 className="text-back dark:text-white">Name</h4>
                  <TextField
                    disabled={isSubmitting}
                    fullWidth
                    onChange={handleChange}
                    placeholder={"Name"}
                    inputSize={"medium"}
                    id={"name"}
                    name="name"
                    onBlur={handleBlur}
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>
                <div>
                  <h4 className="text-back dark:text-white">Price</h4>
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    placeholder={"Price"}
                    inputSize={"medium"}
                    id={"price"}
                    name="price"
                    onBlur={handleBlur}
                    error={
                      touched.price && errors.price && Boolean(errors.price)
                    }
                    helperText={touched.price && errors.price && errors.price}
                  />
                </div>
                <div className="">
                  <h4 className="dark:text-white text-black">
                    Variation Size{" "}
                    <span className="text-gray-400">(optional)</span>
                  </h4>
                  <MutipleSelectBox
                    name={"Variation Sizes"}
                    selected={variationSizes}
                    setSelected={setVariationSize}
                    data={sizes}
                  />
                </div>
                <div className="relative">
                  <h4 className="dark:text-white text-black ">
                    Variation Colors{" "}
                  </h4>
                  <div className="border p-3 rounded  text-black dark:text-white">
                    {chooseColor?.color_code ? (
                      <span className="uppercase">
                        {chooseColor?.color_code}
                      </span>
                    ) : (
                      <span className="text-gray-400">Colors</span>
                    )}
                  </div>
                  <div
                    onClick={() => setColorToggle((prev) => !prev)}
                    className="cursor-pointer p-2 rounded-full hover:bg-black disabled:hover:bg-inherit disabled:cursor-not-allowed disabled:text-gray-500 dark:disabled:text-gray-800 hover:bg-opacity-10 ring-red-500 focus:ring-4 absolute top-7 inline-block right-0"
                  >
                    {colorToggle ? (
                      <ChevronUpIcon className="w-6 h-6 text-black dark:text-white  cursor-pointer" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6 text-black dark:text-white  cursor-pointer" />
                    )}
                  </div>
                  {colorToggle && (
                    <div className="flex flex-col p-2 z-40 bg-main-dark-bg rounded absolute top-20 w-full h-[300px] overflow-y-auto">
                      <div className="grid grid-cols-12 gap-2">
                        {Array.isArray(colorData) &&
                          colorData.length > 0 &&
                          colorData.map((color) => (
                            <div
                              onClick={() => setChooseColor(color)}
                              key={color.id}
                              className={clsx(
                                "col-span-2 p-8 rounded  cursor-pointer",
                                {
                                  "border-2 border-red-500":
                                    chooseColor?.id === color.id,
                                }
                              )}
                              style={{ background: color.color_code }}
                            >
                              <p className="text-white">{color.color_code}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h4 className="dark:text-white text-black">Store</h4>
                  <TextField
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={isSubmitting}
                    placeholder="Store"
                    name="store"
                    id="store"
                    inputSize={"medium"}
                    fullWidth
                    error={
                      touched.store && errors.store && Boolean(errors.store)
                    }
                    helperText={touched.store && errors.store && errors.store}
                  />
                </div>
                <div className="w-full">
                  <h4 className="dark:text-white text-black">Description</h4>
                  <MyRichTextEditor
                    description={description}
                    setDescription={setDescription}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
                <div className="flex flex-row justify-end mt-6">
                  <ActionButton
                    disabled={isSubmitting}
                    bgColor={currentColor}
                    color={"#fff"}
                    type="submit"
                    borderRadius={10}
                    text={isSubmitting ? "Creating..." : "Create"}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateProductVariation;
