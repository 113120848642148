import { gql } from "@apollo/client";

export const GET_CREATE_PRODUCT = gql`
  query MyQuery {
    product_categories {
      id
      name
    }
    product_subcategories {
      id
      name
    }
    product_brands {
      id
      name
    }
    product_activities {
      id
      name
    }
    vendors {
      id
      name
    }
    body_types {
      id
      name
    }
    product_collections {
      id
      name
    }
    sizes {
      id
      name
    }
    variation_colors {
      id
      name
      color_code
    }
  }
`;
