import { gql } from "@apollo/client";

export const GET_SINGLE_PRODUCT = gql`
  query MyQuery($id: Int!) {
    products_by_pk(id: $id) {
      name
      id
      description
      disabled
      price
      promotion
      sub_title
      product_image_url
      status
      product_images {
        id
        product_image_url
      }
      product_variations {
        id
        product_image_url
        name
        price
        status
      }
      product_collection {
        name
        id
      }
      brand {
        id
        name
      }
      body_type {
        name
        id
      }
      product_category {
        name
        id
      }
      product_subcategory {
        id
        name
      }
      product_type {
        id
        name
      }
      activity {
        name
        id
      }
      vendor {
        id
        name
        phone
      }
    }
  }
`;
