import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_COLLECTION = gql`
  mutation MyMutation(
    $id: Int!
    $name: String!
    $description: String!
    $collection_image_url: String!
  ) {
    update_product_collections_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        collection_image_url: $collection_image_url
      }
    ) {
      collection_image_url
      description
      id
      name
    }
  }
`;
