import { gql } from "@apollo/client";

export const GET_ALL_PRODUCT_VARIATION = gql`
  query MyQuery($fk_product_id: Int!) {
    product_variations(where: { fk_product_id: { _eq: $fk_product_id } }) {
      id
      name
    }
  }
`;
