import { gql } from "@apollo/client";

export const getAllUsers = gql`
  query MyQuery {
    users {
      email
      id
      name
      password
      phone
    }
  }
`;
