import React from "react";
import Header from "../components/Header";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ActionButton, TextField } from "../anh";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { GET_ALL_COLORS } from "../graphql/queries/getAllColors";
import { GET_ONE_COLOR } from "../graphql/queries/getOneColor";
import { UPDATE_COLOR_BY_ID } from "../graphql/mutations/updateColor";
const UpdateVariationColor = () => {
  const { currentColor } = useStateContext();
  const formikRef = React.useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const colorId = parseInt(id);
  //Get One Data
  const [loadColor, getColor] = useLazyQuery(GET_ONE_COLOR);
  const color = getColor?.data?.variation_colors_by_pk
    ? getColor.data.variation_colors_by_pk
    : null;
  React.useEffect(() => {
    loadColor({
      variables: {
        id: colorId,
      },
    });
  }, [colorId, loadColor]);

  const [update_color] = useMutation(UPDATE_COLOR_BY_ID, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: (result) => {
      toast.success("New Color has been added");
      navigate("/variation-colors");
    },
    refetchQueries: () => [
      {
        query: GET_ALL_COLORS,
      },
    ],
  });
  //Show Values
  React.useEffect(() => {
    formikRef.current.setFieldValue("name", color?.name);
    formikRef.current.setFieldValue("code", color?.color_code);
  }, [color?.color_code, color?.name]);
  return (
    <div className="p-8 dark:text-white">
      <Header title={"Update Color"} category="Colors" />
      <Link
        to={"/variation-colors"}
        style={{ background: currentColor }}
        className="text-white outline-none mb-4 inline-block px-3 py-2 rounded-lg"
      >
        Back
      </Link>
      <div className="mt-4 flex flex-row justify-center">
        <div className="p-8 bg-white rounded dark:bg-secondary-dark-bg w-full lg:w-5/6 shadow">
          <Formik
            innerRef={formikRef}
            initialValues={{
              name: "",
              code: "",
            }}
            validationSchema={Yup.object().shape({
              code: Yup.string().max(255).required("color code required!"),
            })}
            onSubmit={async (values) => {
              try {
                await update_color({
                  variables: {
                    id: colorId,
                    color_code: values.code,
                    name: values.name,
                  },
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="mb-3">
                  <h4 className="mb-2 dark:text-white text-back">Name</h4>
                  <TextField
                    name="name"
                    id={"name"}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="Name"
                    type="text"
                    inputSize="medium"
                    fullWidth
                    error={touched.name && errors.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name && errors.name}
                  />
                </div>
                <div className="mb-3">
                  <h4 className="mb-2 dark:text-white text-back">
                    Color Code
                    <span className="text-gray-400">(eg.#fffff,red,)</span>
                  </h4>
                  <TextField
                    name="code"
                    id={"code"}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                    placeholder="Color Code"
                    type="text"
                    inputSize="medium"
                    fullWidth
                    error={touched.code && errors.code && Boolean(errors.code)}
                    helperText={touched.code && errors.code && errors.code}
                  />
                </div>
                <div className="">
                  <ActionButton
                    disabled={isSubmitting}
                    type="submit"
                    text={isSubmitting ? "Creating.." : "Save"}
                    bgColor={currentColor}
                    width={"full"}
                    color={"#fff"}
                    borderRadius={8}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariationColor;
